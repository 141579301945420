.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.bigsize{
  display: block !important;
}
.smallsize{
  display: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-search-holder {
  width: 100%;
  position: relative;
}
.my-input:focus, .my-input:focus-visible,  .header-search-holder .MuiInput-focused {
  outline: none !important;
  border: 2px solid #FF3535;
}
.my-input {
  width: 100%;
  padding: 15px 20px;
  border-radius: 40px;
  border: 1px solid #FF3535;
}
.go-btn {
  position: absolute !important;
  right: 50px;
  top:6px;
  border-radius: 20px !important;
  background-color: #FF3535 !important;
  color: white !important;
  cursor: pointer;
}
.search-close {
  position: absolute !important;
  right: 20px;
  top:13px;
  cursor: pointer;
}
.search-close svg path {
  fill: #FF3535;
}
#search-icon {
  margin-top: 5px;
  cursor: pointer;
}

@media only screen and (max-width:1160px ) {

  .bigsize{
    display: none !important;
  }
  .smallsize{
    display: block !important;
  }
}

@media only screen and (min-width:1161px ) {

  .smallsize{
    display: none !important;
  }
  .bigsize{
    display: block !important;
  }
}
