.formStyles {
  width: 95%;
  height: 50px;
  border: 1px solid #d8d8d8;
  background: #ffffff00 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  margin-left: 13px;
  margin-bottom: 25px;
  padding: 15px 0px 15px 17px;
}
.err-input {
  border: 1px solid #ff3535 !important;
  box-shadow: 0px 2px 10px #00000029 !important;
}

.pricing-container {
  padding-right: 15px;
  padding-left: 15px;
}
@media only screen and (min-width: 1000px) {

/* .pricing-holder-img, .pricing-holder {
  height: 430px !important;
} */
}
/* mobile devices */
@media only screen and (max-width: 639px) {
  .branding-logo {
    flex-direction: column !important;
    align-items: baseline !important;
    gap: 10px !important;
  }
  .walmart-logo-brand {
    width: 60px !important;
  }
  .pricing-form-alert-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;

    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px;
  }
  .pricing-form-popup {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90% !important;
    max-height: 84%;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 16px;
  }
  .laptop-pricing-popup-content {
    display: none;
  }
  .mobile-pricing-popup-content {
    display: block;
  }

  .pricing-table {
    max-width: 100 !important;
  }
  .table-row {
    padding: 3px !important;
    align-content: center;
  }
  .table-cell {
    padding: 3px !important;
  }
  .table-cell-1 {
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 3px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .table-row-1 {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .pricing-plan-cell {
    max-width: 80px;
  }
  .exclamatory-icon {
    height: 15px;
    width: 15px;
  }
  .exclamatory-icon-1 {
    height: 15px;
    width: 15px;
    margin-left: 60px;
  }
  .pricing-subscribe-btn {
    border-radius: 8px;
    opacity: 1;
    border-color: #ff3535 !important;
    height: 30px !important;
    width: 50px !important;
    text-transform: unset;
    font-size: 8px !important;
    line-height: 10px !important;
    background-color: #fff;
    color: #ff3535;
    /* margin-left: 10px !important; */
  }
  .tick-img {
    height: 15px;
    /* width: 15px; */
  }
  .value-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 30px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .pricing-header-container {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .pricing-header-img {
    width: 100%;
  }
  .heading-text {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .pricing-heading-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Trajan Pro Bold !important;
    font-weight: 400 !important;
    line-height: 43px !important;
  }
  .pricing-container {
    justify-content: center;
    margin: auto;
    padding-right: 20px;
    padding-left: 20px;
    /* width: 100%; */
  }

  .MuiTooltip-popper[data-popper-placement*="bottom"]
    .css-1hjrgzd-MuiTooltip-tooltip {
    margin-top: 10px !important;
  }

  .MuiTooltip-popper[data-popper-placement*="top"]
    .css-1hjrgzd-MuiTooltip-tooltip {
    margin-bottom: 10px !important;
  }
}

/*laptop devices */
@media only screen and (min-width: 1000px) {
  .pricing-form-alert-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px;
  }
  .pricing-form-popup {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 605px;
    height: 630px;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 16px;
  }
  .laptop-pricing-popup-content {
    display: block;
  }
  .mobile-pricing-popup-content {
    display: none;
  }
  .pricing-table {
    min-width: 650;
  }
  .table-row {
    padding: 6px !important;
    align-content: center;
  }
  .pricing-plan-cell {
    max-width: 200px;
  }
  .exclamatory-icon {
    height: 25px;
    width: 25px;
    margin-left: 5px;
  }
  .exclamatory-icon-1 {
    height: 25px;
    width: 25px;
    margin-left: 30px;
    margin-right: 10px;
  }
  .tick-img {
    height: 40px;
    /* width: 40px; */
  }
  .pricing-subscribe-btn {
    border-radius: 10px !important;
    opacity: 1;
    border-color: #ff3535 !important;
    height: 40px;
    width: 170px;
    background-color: #fff;
    color: #ff3535;
  }
  .table-cell {
    padding: 16px !important;
  }
  .table-cell-1 {
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 16px !important ;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .value-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 24px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 37px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .pricing-header-container {
    margin-left: auto;
    margin-right: auto ;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95% ;
  }
  .pricing-header-img {
    width: 511px;
    height: 200px;
  }
  .heading-text {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .pricing-heading-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Trajan Pro Bold !important;
    font-weight: 400 !important;
    line-height: 43px !important;
  }
  .pricing-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
}
/* large devices */
@media only screen and (min-width: 1200px) {
  .pricing-form-alert-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;

    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px;
  }
  .pricing-form-popup {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 605px;
    height: 630px;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 16px;
  }
  .laptop-pricing-popup-content {
    display: block;
  }
  .mobile-pricing-popup-content {
    display: none;
  }

  .pricing-table {
    min-width: 650;
  }
  .pricing-plan-cell {
    max-width: 250px !important;
    margin-left: 10%;
  }
  .exclamatory-icon {
    height: 25px;
    width: 25px;
  }
  .exclamatory-icon-1 {
    height: 25px;
    width: 25px;
    margin-left: 50px;
  }
  .table-row {
    padding: 16px;
    align-content: center;
  }
  .tick-img {
    height: 40px;
    /* width: 40px; */
  }
  .pricing-subscribe-btn {
    border-radius: 10px !important;
    opacity: 1;
    border-color: #ff3535 !important;
    height: 40px !important ;
    width: 150px;
    background-color: #fff;
    color: #ff3535;
  }
  .table-cell {
    padding: 16px !important;
  }
  .table-cell-1 {
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 16px !important ;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    /* align-content: cen; */
  }
  .value-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 24px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 37px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .pricing-header-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 95%;
    width: 1370px;
  }
  .heading-text {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .pricing-heading-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Trajan Pro Bold !important;
    font-weight: 400 !important;
    line-height: 43px !important;
  }
  .pricing-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    width: 1370px;
  }
}
/* tablet devices */
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .pricing-form-alert-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;

    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px;
  }
  .pricing-form-popup {
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 605px;
    height: 630px;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 16px;
  }
  .laptop-pricing-popup-content {
    display: block;
  }
  .mobile-pricing-popup-content {
    display: none;
  }

  .pricing-table {
    max-width: 700 !important;
  }
  .pricing-plan-cell {
    max-width: 250px !important;
    margin-left: 40%;
  }
  .exclamatory-icon {
    height: 25px;
    width: 25px;
  }
  .exclamatory-icon-1 {
    height: 25px;
    width: 25px;
    margin-left: 30%;
  }
  .table-row {
    padding: 16px;
    align-content: center;
  }
  .tick-img {
    height: 30px;
    /* width: 30px; */
  }
  .pricing-subscribe-btn {
    border-radius: 8px;
    opacity: 1;
    border-color: #ff3535 !important;
    height: 30px !important;
    width: 50px !important;
    text-transform: unset;
    font-size: 8px !important;
    line-height: 10px !important;
    background-color: #fff;
    color: #ff3535;
    margin-left: 10px !important;
  }
  .table-cell {
    padding: 16px !important;
  }
  .table-cell-1 {
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 8px !important ;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    /* align-content: cen; */
  }
  .value-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 24px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 37px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .pricing-header-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .pricing-header-img {
    width: 100%;
  }
  .heading-text {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .pricing-heading-font {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Trajan Pro Bold !important;
    font-weight: 400 !important;
    line-height: 43px !important;
  }
  .pricing-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .MuiTooltip-popper[data-popper-placement*="bottom"]
    .css-1hjrgzd-MuiTooltip-tooltip {
    margin-top: 10px !important;
  }
  .MuiTooltip-popper[data-popper-placement*="top"]
    .css-1hjrgzd-MuiTooltip-tooltip {
    margin-bottom: 10px !important;
  }
}
