/* @media only screen and (max-width:639px) {
    .plans-container{
        background-color: #FAFAFB;
        padding-bottom: 50px;
    }
}
@media only screen and (min-width: 641px) and (max-width: 1319px) {
    .plans-container{
        background-color: #FAFAFB;
        padding-bottom: 70px;
    }
}

@media only screen and (min-width:1320px) {
    .plans-container{
        background-color: #FAFAFB;
        padding-bottom: 70px; 
    }
} */

.plans-container{
    padding-right: 15px;
    padding-left: 15px;
}

/*mobile devices*/
@media only screen and (max-width: 767px) {
    .pricing-plan-alignment{
        display: flex;
        flex-direction: column !important;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px !important;
    }
    
    .basic-plan-img{
        width: 50%;
        margin-top: 15px;
        cursor: pointer;
    }
    .premium-plan-img{
        width: 50%;
        margin-top: 15px;
        cursor: pointer;
    }
    .plans-container{
        justify-content: center;
        margin: auto;
        padding: 0px;
        width: 100%;
    }
}

/* tablet */
@media only screen and (min-width: 768px) {
    .pricing-plan-alignment{
        margin-top: 5% !important;
        display: flex !important;
        justify-content: space-evenly !important;
    }

    .basic-plan-img{
        width: 30% !important;
        cursor: pointer;
    }
    .premium-plan-img{
        width: 30% !important;
        cursor: pointer;
    }
    .plans-container{
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        padding-left: 20px;
        padding-right:20px;
        width: 100%;
    }
}

/* laptop devices */
@media only screen and (min-width:999px) and (max-width:1199px) {
    .pricing-plan-alignment{
        margin-top: 5% !important;
        display: flex;
        justify-content: space-around !important;
    }
    .basic-plan-img{
        width: 25% !important;
        cursor: pointer;
    }
    .premium-plan-img{
        width: 25% !important;
        cursor: pointer;
    }
    .plans-container{
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        padding-left: 0;
        padding-right: 0;
        width: 95%;
    }
}

/* large devices */
@media only screen and (min-width:1200px) {
    .pricing-plan-alignment{
        margin-top: 5% !important;
        display: flex !important;
        justify-content: space-evenly !important;
    }
    .basic-plan-img{
        width: 45%;
        cursor: pointer;
    }
    .premium-plan-img{
        width: 45%;
        cursor: pointer;
    }
    .plans-container{
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        width: 1370px;
    }
}

