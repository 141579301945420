.header-navbar-1 {
  height: 60px;
  background: #2b303e 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  align-items: center;
  /* display: grid;
  margin: 0px !important; */
}

/* .header-nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.social-icon {
  padding-right: 15px;
}

.primary {
  background: #ff3535 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
}

.menu-top-bar {
  color: black !important;
  height: 90px;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  border-bottom: 5px solid transparent !important;
}

.menu-top-bar:hover {
  letter-spacing: 0px;
  color: #ff3535 !important;
  opacity: 1;
  border-bottom: 5px solid #ff3535;
  background-color: white !important;
}

.active-menu {
  letter-spacing: 0px;
  color: #ff3535 !important;
  opacity: 1;
  padding-top: 10px;
  border-bottom: 5px solid #ff3535 !important;
  background-color: white !important;
}

/* #language-select {
  border: 1px solid var(--unnamed-color-ff3535);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ff3535 !important;
  border-radius: 5px;
  opacity: 1;
  width: auto;
  padding-top: 10px;
  padding-bottom: 10px;
} */

#language-select {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #ff3535 !important;
  border-radius: 5px;
  opacity: 1;
  padding-bottom: 10px;
  padding-top: 10px;
  width: auto;
}

#login {

  border-radius: 5px !important;
  background: #ff3535 !important;
  color: #fff !important;
  opacity: 1;
  border-color: #ff3535 !important;
  text-transform: unset !important;
  /* width: 120px !important;
  height: 40px ; */
  font-size: 20px !important;
  line-height: 30px !important;
  font-weight: 500 !important;
}

#fade-menu ul {
  padding: 0px !important;
}

.submenu-header {
  color: black !important;
  text-align: left;
  border-bottom: 1px solid #D8D8D8 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.submenu-header:hover {
  color: #ff3535 !important;
  opacity: 1;

  background-color: white !important;
}

.side-bar {
  color: white;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

.side-bar:hover {
  letter-spacing: 0px;
  color: #ff3535 !important;
  opacity: 1;

}

.side-menu {
  letter-spacing: 0px;
  color: #ff3535 !important;
  opacity: 1;
}

.language-container {

  display: flex;
  gap: 8px;
  padding-left: 11px;
  padding-right: 11px;
  min-width: 88px;
  max-height: 39px;
}

/* }
.container-topbar {
  padding-right: 15px;
  padding-left: 15px; 
/* } */
/* .header-navbar-container{
  padding-right: 15px;
  padding-left: 15px;
} */

.sign-up-container{
  height: 41px;
  display: flex;
  align-items: center;
  margin-left: 18px;
}
.sign-up-btn{
  height: 41px !important;
  background-color: red !important;
  color: white !important;
  padding: 0px 20px !important;
  text-transform: unset !important;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

/* small devices */
@media only screen and (max-width:767px) {
  .header-navbar-container {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  #mrflogo {
    margin-left: 10px;
    width: 195px;
  }
  .social-icon {
    padding-right: 9px;
  }
  .language-container {

    display: flex;
    gap: 6px;
    margin: 0px auto;
    padding-left: 7px;
    padding-right: 0px;
    min-width: 40px !important;
    max-height: 27px;

  }
  .sign-up-container{
    height: 34px;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .sign-up-btn{
    height: 34px !important;
    background-color: red !important;
    color: white;
    padding: 0px !important;
    text-transform: none;
  }
 .social-icon-logo{
  width: 29px;
  height: 29px;
 }

  .header-navbar-container {
    /* padding-top: 7px; */

    margin: auto;

    width: 100%;
  }

  .header-nav-bar {
    /* margin-left: -30px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .MuiSelect-select.MuiInputBase-input.MuiSelect-select{
    height: 0px;
  }
  .MuiButtonBase-root.MuiButton-root{
    height: 34px;
min-width: 54px;
  }
  .MuiPaper-root.MuiDrawer-paper {

    width: 100%;
    background-color: #2B303E;
    margin-bottom: 0px;
    overflow-y: auto;
  }

  /* IntialModal */
  .intialize-mfdlogo {
    margin-top: 5px;
    width: 100%;
  }
  .offer-form-alert-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;

    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px;
  }
  .offer-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    outline: none;
    transform: translate(-50%, -50%);
    width: 70%;
    /* max-height: 300px; */
    background-color: #fff;
    border:none ;
    border-radius: 28px;
    padding: 0px;

  }
  .content-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    outline: none;
    transform: translate(-50%, -50%);
    width: 70%;
    /* max-height: 300px; */
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
   
  }
  .coming-soon{
    width: 60%;
  }
  .coming-soon-close-container{
    position: fixed;
    left: 93%;
    top: -7.7%;
  }
  
  .laptop-offer {
    display: none;
  }

  .mobile-offer {
    display: block;
  }
  .close-logo{
    background-image: url("../../../../assets/close-icon-red.png");

  }
  .offer-logo {
    margin-top: -10px;
    margin-left: 5px;
    margin-bottom: 6px;
    width: 95%;
    height: 46px;

  }
  .mail-logo-container {
margin: 10px 0px;
    width: 120px;
    height: 120px;
    border: 2px solid white;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;  

  }

  .mail-logo {
    /* margin-top: 30px;
    margin-bottom: 30px; */
    width: 78px;
    height: 57px;
  }
  
.count-me-in-btn {
  width: 112px;
  height: 25px;
  border-radius: 25px !important;
  background:#ffffff !important;
  color:  #D13A3C  !important;
  opacity: 1;
  margin-bottom: 10px !important;
  text-transform: unset !important;
  padding: 15px !important;
  ;
}
  
.count-me-logo {
  width: 67%;
  margin-top: 6px;
  margin-bottom: 5px;
}

.offerform {
  width: 100% !important;
  background: none !important;
  height: 40px;
  border: 1px solid #C4C4C4 !important;
  box-shadow: inset 0 0 3px 1px #C4C4C4 !important;
  border-radius: 35px;
  opacity: 1;
  margin-bottom: 4px;
  margin-top: 5px;
  padding: 15px 0px 15px 17px;

}

.err-input-offer {
  border: 1px solid #FF3535 !important;
  box-shadow: 0px 2px 10px #00000029 !important;

}

}

/* tablet devices */

@media only screen and (min-width: 768px) and (max-width:999px) {
  #mrflogo {

    width: 296px;
    height: 63px;
  }

  .header-navbar-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .header-nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MuiPaper-root.MuiDrawer-paper {
    width: 320px !important;
    background-color: #2B303E;
    overflow-y: auto;
  }
  .MuiButtonBase-root.MuiButton-root{
    min-width: 70px;
      }
  /* IntialModal */
  .intialize-mfdlogo {
    margin-top: 10px;
    width: 100%;
  }
  .offer-form-alert-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;

    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px;
  }
  .offer-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    outline: none;
    transform: translate(-50%, -50%);
    width: 400px !important;
    /* max-height: 300px; */
    background-color: #fff;
    border-radius: 28px;
    padding: 0px;

  }
  .content-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    outline: none;
    transform: translate(-50%, -50%);
    width: 400px !important;
    height: 200px;
    /* max-height: 300px; */
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
   
  }
  .coming-soon{
    width: 47%;
  }
  .coming-soon-close-container{
    position: fixed;
    left: 94%;
    top: -7.7%;
  }
.laptop-offer{
  display: none;
}
  .offer-logo {
    margin-top: -18px;
    margin-left: 7px;
    margin-bottom:11px;
    width: 95%;
    height: 56px;
  }
  .mail-logo-container {
margin: 20px 0px;
    width: 120px;
    height: 120px;
    border: 2px solid white;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;  

  }

  .mail-logo {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 78px;
    height: 57px;
  }
  
.count-me-in-btn {
  width: 112px;
  height: 25px;
  border-radius: 25px !important;
  background:#ffffff !important;
  color:  #D13A3C  !important;
  opacity: 1;
  margin-bottom: 20px !important;
  text-transform: unset !important;
  padding: 15px !important;
  ;
}
  
.count-me-logo {
  width: 70%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.offerform {
  width: 100% !important;
  height: 40px;
  border: 1px solid #C4C4C4 !important;
  box-shadow: inset 0 0 3px 1px #C4C4C4 !important;
  border-radius: 35px;
  opacity: 1;
  margin-bottom: 10px;
  margin-top: 5px;
  padding: 15px 0px 15px 17px;

}

.err-input-offer {
  border: 1px solid #FF3535 !important;
  box-shadow: 0px 2px 10px #00000029 !important;

}

}

/* laptop device */
@media only screen and (min-width: 1000px) {

  .header-navbar-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }

  #mrflogo {
    margin: 5px 0px 0px -12px !important;
    width: 389px;
    height: 79px;
  }

  .header-nav-bar {

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MuiPaper-root.MuiDrawer-paper {
    width: 320px !important;
    background-color: #2B303E;

    overflow-y: auto;
  }
/* intialize popup */
  .intialize-mfdlogo {
    margin-top: 10px;
    margin-bottom: 4px;
  
    width: 100%;
  }
  
  .count-me-in-btn {
    width: 112px;
    height: 25px;
    border-radius: 25px !important;
    background:#ffffff !important;
    color:  #D13A3C  !important;
    opacity: 1;
    margin-bottom: 20px !important;
    /* margin: 0px auto 7px !important; */
    /* border-color: #D13A3C !important; */
    text-transform: unset !important;
    padding: 15px !important;
    ;
  }
  
  .count-me-logo {
    /* width: 100%; */
    margin-top: 6px;
    margin-bottom: 6px;
  }
  
  .offer-logo {
    margin-top: -17px;
    margin-left: 8px;
    margin-bottom: 6px;
    width: 99%;
    height: 58px;
  }
  
  .mail-logo {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 92%;
    height: 165px;
  }
  
  .offerform {
    width: 100% !important;
    height: 44px;
    border: 1px solid #C4C4C4 !important;
    box-shadow: inset 0 0 3px 1px #C4C4C4 !important;
    /* background: #FFFFFF00 0% 0% no-repeat padding-box; */
    border-radius: 35px;
    opacity: 1;
    /* margin-left:2% !important; */
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 15px 0px 15px 17px;
  
  }
  
  .err-input-offer {
    border: 1px solid #FF3535 !important;
    box-shadow: 0px 2px 10px #00000029 !important;
  
  }
  .offer-form-alert-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;

    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px;
  } 
  .offer-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    width: 697px !important;
    /* max-height: 500px; */
    background-color: #fff;
    /* border: none !important ; */
    border-radius: 28px;
    padding: 8px;
  
  }
  .content-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    outline: none;
    transform: translate(-50%, -50%);
    width: 400px !important;
height: 200px;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
   
  }
  .coming-soon{
    width: 47%;
  }
  .coming-soon-close-container{
    position: fixed;
    left: 94%;
    top: -7.7%;
  }
  .mobile-offer {
    display: none;
  }
  .laptop-offer {
    display: block;
  }


}

/* large device */
@media only screen and (min-width: 1200px) {
  .header-navbar-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    width: 1370px;
  }

  #mrflogo {
    margin: 5px 0px 0px -12px !important;
    width: 389px;
    height: 79px;
  }

  .header-nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

}