@media only screen and (max-width:767px) {
.home-topcontainer-title{
padding: 30px 0px 30px 0px;
} 
  .naturaldisaster-title{
   text-align: center; 
   padding: 0px 0px 15px 0px;
  }
 .naturaldisaster-contentpara{
  text-align: center;
  padding-bottom: 30px;
 }
  .manmadedisaster-title{
    text-align: center; 
    padding: 0px 0px 15px 0px;
  }
  .custom-carousel .react-multiple-carousel__arrow--left {
    left: 15px;
  }


  .custom-carousel .react-multiple-carousel__arrow--right {
    right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .home-topcontainer-title{
    padding: 30px 0px 30px 0px;
    } 
  .naturaldisaster-title{
    text-align: center; 
    padding: 0px 0px 15px 0px;
   }
   .naturaldisaster-contentpara{
    text-align: center;
    padding-bottom: 64px;
   }
   .manmadedisaster-title{
    text-align: center; 
    padding: 0px 0px 15px 0px;
  }

  .custom-carousel .react-multiple-carousel__arrow--left {
    left: 15px;
  }


  .custom-carousel .react-multiple-carousel__arrow--right {
    right: 15px;
  }
}

@media only screen and (min-width:1000px) {
  .home-topcontainer-title{
    padding: 0px 0px 30px 0px;
    } 
  .naturaldisaster-title{
    text-align: center; 
    padding: 0px 0px 15px 0px;
   }
   .naturaldisaster-contentpara{
    text-align: center;
    padding-bottom: 64px;
   }
   .manmadedisaster-title{
    text-align: center; 
    padding: 0px 0px 15px 0px;
  }

  .custom-carousel .react-multiple-carousel__arrow--left {
    left: 40px;
  }


  .custom-carousel .react-multiple-carousel__arrow--right {
    right: 40px;
  }
}

.custom-carousel .react-multiple-carousel__arrow:hover {
  background-color: #E60404;
  cursor: pointer;
}

.custom-carousel .react-multiple-carousel__arrow {
  background-color: #ff0000;
  cursor: pointer;
}


@media only screen and (min-width:1200px) {
  .home-topcontainer-title{
    padding: 0px 0px 30px 0px;
    } 
  .naturaldisaster-title{
    text-align: center; 
    padding: 0px 0px 15px 0px;
   }
   .naturaldisaster-contentpara{
    text-align: center;
    padding-bottom: 64px;
   }
   .manmadedisaster-title{
    text-align: center; 
    padding: 0px 0px 15px 0px;
  }

  .custom-carousel .react-multiple-carousel__arrow--left {
    left: 40px;
  }


  .custom-carousel .react-multiple-carousel__arrow--right {
    right: 40px;
  }
}

.custom-carousel .react-multiple-carousel__arrow:hover {
  background-color: #E60404;
  cursor: pointer;
}

.custom-carousel .react-multiple-carousel__arrow {
  background-color: #ff0000;
  cursor: pointer;
}