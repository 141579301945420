/* mobile devices */
@media only screen and (max-width:767px) {
    .ourservices-header-container {
        margin: auto;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
    .ourservices-header-img {
        width: 100%;
    }
    .emergency-title{
margin-top: 30px;
    }
    .emergency-middle-content{
        margin: 20px 0px 30px;
    }
    .emergency-img{
        width: 100%;
        /* margin: 40px 0px 20px 0px; */

    }
    .tab-middle-title{
        margin-bottom: 17px !important;
    }
    .laptop-view{
        display: none;
    }
    .btn-container{
        display: flex;
        justify-content: center;
        margin: 30px 0px 40px;
    }
    .order-btn {
        border-radius: 8px !important;
        background: #fff !important ;
        color: #ff3535 !important;
        opacity: 1;
        border-color: #ff3535 !important;
        text-transform: unset !important;
        width: 80%;
        height: 60px;
        letter-spacing: 0.11px;
        line-break: 24px;
      }
      .order-btn:hover {
        background: #ff3535 !important ;
        color: #fff !important;
      }
      .MuiAccordionSummary-expandIconWrapper.Mui-expanded{
        transform: none !important;
      }
}

/* tablet devices */
@media only screen and (min-width:768px) {

    .ourservices-header-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
    .ourservices-header-img {

        width: 100%;
    }
    .emergency-title{
        margin-top: 40px;
            }
            .emergency-middle-content{
                margin: 40px 0px;
            }
    .emergency-img{
        width: 100%;
        margin: 40px 0px 20px 0px;

    }
    .tab-middle-title{
        margin-bottom: 10px !important;
    }
    .btn-container{
        display: flex;
        justify-content: center;
        margin: 50px 0px;
    }
    .order-btn {
        border-radius: 8px !important;
        background: #fff !important ;
        color: #ff3535 !important;
        opacity: 1;
        border-color: #ff3535 !important;
        text-transform: unset !important;
        width: 60%;
        height: 60px;
        letter-spacing: 0.11px;
        line-break: 24px;
      }
      .order-btn:hover {
        background: #ff3535 !important ;
        color: #fff !important;
      }
      .MuiAccordionSummary-expandIconWrapper.Mui-expanded{
        transform: none !important;
      }
      .mobile-view{
        display: none;
            }
}

/* laptop devices */
@media only screen and (min-width:1000px) {
    .ourservices-header-container {
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: 95%;
        padding-left: 0;
        padding-right: 0;
        width: 95% !important;
    }
    .ourservices-header-img {
        width: 511px;
        height: 200px;
    }
    .emergency-title{
        margin-top: 50px;
            }
            .emergency-middle-content{
                margin: 50px 0px;
            }

    .emergency-img{
        width: 600px;
        height: 400px;
        margin: 40px 0px 20px 0px;

    }
    .tab-middle-title{
        margin-bottom: 17px !important;
    }
     .btn-container{
        display: flex;
        justify-content: center;
        margin: 50px 0px;
    }
    .order-btn {
        border-radius: 8px !important;
        background: #fff !important ;
        color: #ff3535 !important;
        opacity: 1;
        border-color: #ff3535 !important;
        text-transform: unset !important;
        width: 480px;
        height: 60px;
        letter-spacing: 0.11px;
        line-break: 24px;
      }
      .order-btn:hover {
        background: #ff3535 !important ;
        color: #fff !important;
      }

   .mobile-view{
display: none;
    }
}
/* largedevices */
@media only screen and (min-width:1200px) {
    .ourservices-header-container {
        margin-left: auto !important;
        margin-right: auto !important;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 95%;
        width: 1370px !important;
    }

  
}