

.list-component li {
    list-style: none;
    padding-bottom: 15px;
    display: flex;
}

.list-component li div {
    padding-right: 10px;
}

.footer-services-title {
    letter-spacing: 1.2px !important;
    color: #2B303E;
}

.footer-services-title-hr {
    width: 77px;
    border: 2px solid#ff3535 !important;
    opacity: 1;
    margin: 10px 0px;
}


.li-text-alignment {
    list-style: none;
    text-align: left;
    letter-spacing: 0.8px !important;
    color: #2B303E;
    opacity: 1;
    cursor: pointer;
}

.li-icon-alignment {
    text-align: left;
    letter-spacing: 0px !important;
    color: #000000;
    opacity: 1;
    cursor: pointer;
}


.footer__bottom {
    height: 97px;
    background: #1B1B1B 0% 0% no-repeat padding-box;
    opacity: 1;
    margin: 0px !important;
}

.copyrights {
    text-align: center;
    letter-spacing: 0.32px !important;
    opacity: 1;
    color: #A0A0A0 !important;
    margin-bottom: 10px;

}

.copyright-app {
    color: #ff3535;
    cursor: pointer;
    padding-left: 3px;
    margin-bottom: 10px;
}

.li-text-alignment:hover {
    cursor: pointer;
    color: #ff3535;
}

#mrflogo-footer {
    width: 280px;
    height: 57px;
}

/* .container-footer {
    padding-right: 15px;
    padding-left: 15px;
} */

/* mobile devices */
@media only screen and (max-width:767px) {
   .container-footer{
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
   }

    .footer__topbar {
        margin: 0px !important;
        /* height: 100px; */
        background: #E9E9E9 0% 0% no-repeat padding-box;
        display: flex;
        align-items: center;
     
       
    }
    .topbar-imgcontainer {
        padding-top: 10px;
        display: flex;
        flex-direction: column !important;
        margin-top: 10px;
        margin-bottom: 10px;
       

    }
    .trustedsite-img {
        margin: 0px auto;
        width: 75%;
        /* height: 58px; */
    }

    .mcafee-img {
        margin: 20px auto 0px auto;
        width: 60%;
        /* height: 70px; */
    }
    

    .topbarmenu-container {
        margin-top: 20px;
    }

    .list-component {
        margin: 0px 20px 0px 0px;
    }
    .footer__bottom {
        
        background: #1B1B1B 0% 0% no-repeat padding-box;
        opacity: 1;
        margin: 0px !important;
        padding: 10px 0px
    }
    .footer-bottom-endcontainer {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-direction: column !important;
    

    }

    .footer-bottom-end-faq {
        display: flex;
        align-items: center;
    }

    .footer-bottom-end-copyrights {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

    .faq {
        text-align: left;

        letter-spacing: 0.32px !important;
        color: #A2A2A2;
        opacity: 1;
        border-right: 1px solid #A2A2A2;
        padding: 0px 12px;
        cursor: pointer;
    }

    .faq:hover {
        color: white;
    }

    .big-size{
        display: none !important;
    }

    .small-size{
        display: block !important;
    }

}

/* tablet devices */

@media only screen and (min-width: 768px) {
    .container-footer {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

    .footer__topbar {
        margin: 0px !important;
        height: 100px;
        background: #E9E9E9 0% 0% no-repeat padding-box;
        display: grid;
        align-items: center;
    }
    .topbar-imgcontainer {
        padding-top: 10px;
        display: flex;
        justify-content: center;

    }
    .trustedsite-img {
        margin-right: 7%;
        width: 350px;
        height: 58px;
    }

    .mcafee-img {
        width: 194px;
        height: 70px;
    }

    .topbarmenu-container {
        display: flex;
        justify-content: space-between;
    }

    .list-component {
        margin: 0px 20px 0px 0px;
    }

    .footer-bottom-endcontainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .footer-bottom-end-faq {
        display: flex;
        align-items: center;

    }

    .footer-bottom-end-copyrights {
        display: flex;
        align-items: center;
    }

    .faq {
        text-align: left;

        letter-spacing: 0.32px !important;
        color: #A2A2A2;
        opacity: 1;
        border-right: 1px solid #A2A2A2;
        padding: 0px 10px 0px 10px;
        cursor: pointer;
    }

    .faq:hover {
        color: white;
    }

    .big-size {
        display: block !important;
    }

    .small-size {
        display: none !important;
    }


}
/* laptap device */
@media only screen and (min-width: 1000px) {
    .container-footer {
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        padding-left: 0px;
        padding-right:0px ;
        width: 95%;
    }

   

    .footer__topbar {
        margin: 0px !important;
        height: 150px;
        background: #E9E9E9 0% 0% no-repeat padding-box;
        display: grid;
        align-items: center;
    }
    .topbar-imgcontainer {
       
        display: flex;
        justify-content: center;
    }
    .trustedsite-img {
        margin-right: 17%;
        width: 350px;
        height: 58px;
    }

    .mcafee-img {
        width: 194px;
        height: 70px;
    }

    .topbarmenu-container {
        display: flex;
        justify-content: space-between;

    }

    .footer-bottom-endcontainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .footer-bottom-end-faq {
        display: flex;
        justify-content: flex-start;
    }

    .footer-bottom-end-copyrights {
        display: flex;

        justify-content: flex-end;
    }

    .faq {
        text-align: left;
        letter-spacing: 0.32px !important;
        color: #A2A2A2;
        opacity: 1;
        border-right: 1px solid #A2A2A2;
        padding: 0px 20px;
        cursor: pointer;
    }

    .faq:hover {
        color: white;
    }

    .big-size {
        display: block !important;
    }

    .small-size {
        display: none !important;
    }
}
/* large device */
@media only screen and (min-width: 1200px) {
    .container-footer {
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 95%;
        width: 1370px;
    }

    .topbar-imgcontainer {
        display: flex;
        justify-content: center;
    }

    .footer__topbar {
        margin: 0px !important;
        height: 150px;
        background: #E9E9E9 0% 0% no-repeat padding-box;
        display: grid;
        align-items: center;
    }

    .trustedsite-img {
        margin-right: 17%;
        width: 350px;
        height: 58px;
    }

    .mcafee-img {
        width: 194px;
        height: 70px;
    }

    .topbarmenu-container {
        display: flex;
        justify-content: space-between;

    }

    .footer-bottom-endcontainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .footer-bottom-end-faq {
        display: flex;
        justify-content: flex-start;
    }

    .footer-bottom-end-copyrights {
        display: flex;

        justify-content: flex-end;
    }

    .faq {
        text-align: left;
        letter-spacing: 0.32px !important;
        color: #A2A2A2;
        opacity: 1;
        border-right: 1px solid #A2A2A2;
        padding: 0px 20px;
        cursor: pointer;
    }

    .faq:hover {
        color: white;
    }

    .big-size {
        display: block !important;
    }

    .small-size {
        display: none !important;
    }
}


