/* mobile devices */
@media only screen and (max-width: 767px) {
  .ourservices-header-container {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .ourservices-header-img {
    background: transparent
      linear-gradient(180deg, #cfd5c7 0%, #f7ebda 65%, #fef2e1 100%);
    height: 74px;
  }

  .playstore_icons {
    width: 100%;
  }
  .disaster-heading {
    margin: 20px 0px 11px !important;
  }
  .disaster-heading1 {
    margin: 10px 0px 8px !important;
  }

  .disaster-middle-container {
    margin: 15px 0px 15px !important;
  }

  /* detail page  */
  .diaster-title {
    margin-top: 13px !important;
  }
  .ourservices-detail-img {
    width: 100%;
    margin-bottom: 13px;
    border-radius: 10px;
  }

  .ourservices-detail-img1 {
    width: 100%;
    margin-bottom: 13px;
    border-radius: 10px;
  }

  .laptop-view {
    display: none;
  }
  .back-icon-container {
    display: flex;
    flex-direction: row;
    margin-top: 2px !important;
  }
  .back-icon {
    margin: -3px 3px 10px 0px !important;
    color: black;
  }
  .risk-location-top-container {
    margin: 30px 0px 30px !important;
  }
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded  {
  min-height: 0px !important;
    max-height: 47px !important;
  }
  .css-o4b71y-MuiAccordionSummary-content.Mui-expanded{
    margin: 10px 0px 0px !important;
  }
  .MuiAccordionDetails-root {
    padding: 0px 15px 7px !important;
  }
  /* privacy page */
  .privacy-title {
    margin: 5px 0px 9px 0px !important ;
  }
  .privacy-para {
    margin: 10px 0px 7px 0px !important ;
  }
  .privacy-content {
    margin: 0px 0px 0px 0px !important ;
  }
  .privacy-middle-container {
    margin: 17px 0px 30px !important;
  }
  /* security page*/
  .secure-para {
    padding-top: 5px;
    /* padding-left: 5px; */
  }
  .secure-top-container {
    margin: 0px 0px 20px !important;
  }
  .secure-title {
    margin-top: 13px !important;
  }
}

/* tablet devices */
@media only screen and (min-width: 768px) {
  .ourservices-header-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .ourservices-header-img {
    background: transparent
      linear-gradient(180deg, #cfd5c7 0%, #f7ebda 65%, #fef2e1 100%);

    height: 200px;
  }
  .playstore_icons {
    width: 87%;
    float: right;
    margin: 7px 0px;
  }
  .appstore-container{
    padding:7px 0px;
  }
  .disaster-heading {
    margin: 24px 0px 12px !important;
  }
  .disaster-heading1 {
    margin: 5px 0px 8px !important;
  }

  .disaster-middle-container {
    margin: 20px 0px 20px !important;
  }

  /* detail page  */
  .diaster-title {
    margin-top: 13px !important;
  }

  .ourservices-detail-img {
    height: 290px;
    width: 50%;
    float: right;
    margin-left: 30px;
    margin-bottom: 33px;
    border-radius: 10px;
  }

  .ourservices-detail-img1 {
    height: 290px;
    width: 50%;
    float: left;
    /* margin-left: 30px; */
    margin-bottom: 16px;
    border-radius: 10px;
  }
 
  .mobile-view {
    display: none;
  }
  .back-icon-container {
    display: flex;
    flex-direction: row;
    margin-top: 15px !important;
  }
  .back-icon {
    margin: 1px 5px 10px 0px !important;
    color: black;
  }

  .risk-location-top-container {
    margin: 30px 0px 30px !important;
  }
  .MuiAccordionDetails-root {
    padding: 0px 10px 5px !important;
}
  /* privacy page */
  .privacy-title {
    margin: 16px 0px 13px 0px !important ;
  }
  .privacy-para {
    margin: 10px 0px 7px 0px !important ;
  }
  .privacy-content {
    margin: 0px 0px 0px 0px !important ;
  }
  .privacy-middle-container {
    margin: 13px 0px 30px !important;
  }
  /* security page*/
  .secure-para {
    padding-top: 7px;
    /* padding-left: 25px; */
  }
  .secure-top-container {
    margin: 0px 0px 25px !important;
  }
}

/* laptop devices */
@media only screen and (min-width: 1000px) {
  .ourservices-header-container {
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95% !important;
  }
  .ourservices-header-img {
    background: transparent
      linear-gradient(180deg, #cfd5c7 0%, #f7ebda 65%, #fef2e1 100%);

    height: 200px;
  }
  .playstore_icons {
    width: 87%;
    float: right;
    margin: 7px 0px;
  }
  .appstore-container{
    padding:7px 0px;
  }
  .disaster-heading {
    margin: 26px 0px 13px !important;
  }
  .disaster-heading1 {
    margin: -3px 0px 12px !important;
  }
  .disaster-middle-container {
    margin: 30px 0px 30px !important;
  }

  /* detail page  */

  .ourservices-detail-img {
    height: 290px;
    width: 540px;
    float: right;
    margin-left: 30px;
    margin-bottom: 33px;
    border-radius: 10px;
  }

  .ourservices-detail-img1 {
    height: 290px;
    width: 540px;
    float: left;
    /* margin-left: 30px; */
    margin-bottom: 20px;
    border-radius: 10px;
  }

  .mobile-view {
    display: none;
  }
  .back-icon {
  }

  .back-icon-container{
display: flex;
flex-direction: row;
margin-top: 15px !important;
  }
  .risk-location-top-container {
    margin: 30px 0px 30px !important;
  }

  /* privacy page */
  .privacy-title {
    margin: 18px 0px 21px 0px !important ;
  }
  .privacy-para {
    margin: 10px 0px 7px 0px !important ;
  }
  .privacy-content {
    margin: 10px 0px 0px 0px !important ;
  }
  .privacy-middle-container {
    margin: 16px 0px 35px !important;
  }
  /*security page*/
  .secure-para {
    padding-top: 15px;
    /* padding-left: 45px; */
  }
  .secure-top-container {
    margin: 0px 0px 30px !important;
  }
}

/* largedevices */
@media only screen and (min-width: 1200px) {
  .ourservices-header-container {
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 95%;
    width: 1370px !important;
  }

  .playstore_icons {
    width: 87%;
    float: right;
    margin: 7px 0px;
  }
  .appstore-container{
    padding:7px 0px;
  }

  /* detail page  */

  .ourservices-detail-img {
    height: 290px;
    width: 540px;
    float: right;
    margin-left: 30px;
    margin-bottom: 33px;
    border-radius: 10px;
  }

  /* .ourservices-detail-img1 {
    height: 290px;
    width: 540px;
    float: left;
    margin-left: 30px;
    margin-bottom: 33px;
    border-radius: 10px;
  } */
}
