.blogs-container {
    padding-right: 15px;
    padding-left: 15px;
  }


/* mobile devices */
@media only screen and (max-width: 639px) {

    .blogs-header {
        margin: auto;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
    .blogs-top-header {
margin-top:18px !important ;
    }
    .blogs-top-header-back {
      margin-top:8px !important;
          }
    .blogs-header-img {
        width: 100%;
      }
      .blogs-container {
            /* justify-content: center; */
            margin: auto !important;
            padding-right: 20px !important;
            padding-left: 20px !important;
            width: 100% !important;
      }
      .blogs-comments-form{
        display: flex !important;
        flex-direction: column !important;
      }
      .mobile-blogs-card{
        width: 100%;
      }
      .MuiAccordionSummary-expandIconWrapper.Mui-expanded{
        transform: none !important;
      }
      .blogs-back-container{
        display: flex;
        flex-direction: row;
        cursor: pointer;
        position:absolute;
         margin-top:-10px
          }
      .blogs-back-icon{
      margin: -2px 3px 10px 0px !important;
      color: black;
      }
}

/* tablet devices */
@media only screen and (min-width: 768px) and (max-width: 999px) {

    .blogs-header {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
    .blogs-top-header {
      margin-top:82px !important ;
          }
          .blogs-top-header-back {
            margin-top:58px !important;
                }
    .blogs-header-img {
        width: 100%;
    }
    .blogs-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        display: flex;
        justify-content: center;
        padding-left: 20px !important;
        padding-right: 20px !important;
        width: 100% !important;
    }
    .blogs-container1 {
      /* margin-left: auto;
      margin-right: auto; */
      max-width: 100%;
      display: flex;
      justify-content: center;
      padding-left: 20px !important;
      padding-right: 20px !important;
      width: 100% !important;
  }
    .blogs-comments-form{
        display: flex !important;
        flex-direction: column !important;
      }
      .mobile-blogs-card{
        width:100%;
      }
      .MuiAccordionSummary-expandIconWrapper.Mui-expanded{
        transform: none !important;
      }
      .blogs-back-container{
        display: flex;
        flex-direction: row;
        cursor: pointer;
        position:absolute;
         margin-top:40px
          }
      .blogs-back-icon{
        margin: 3px 5px 10px 0px !important;
        color: black;
        }
}

/*laptop devices */
@media only screen and (min-width: 1000px) {

    .blogs-header {
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        padding-left: 0;
        padding-right: 0;
        width: 95%;
    }
    .blogs-top-header {
      margin-top:82px !important ;
          }
          .blogs-top-header-back {
            margin-top:58px !important;
                }
    .blogs-header-img {
        width: 511px;
        height: 200px;
    }
    .blogs-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        padding-left: 0;
        padding-right: 0;
        width: 95%;
    }
    .blogs-comments-form{
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;
      }
      .blogs-detailed-image{
        width:600px;
        height:486px;
      }
}

/* large devices */
@media only screen and (min-width: 1200px) {

    .blogs-header {
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 95%;
        width: 1370px;
    }
    .blogs-header-img {
        width: 511px;
        height: 200px;
    }
    .blogs-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        width: 1370px;
    }
    .blogs-comments-form{
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;
      }
      .blogs-detailed-image{
        width:830px;
        height:700px;
      }
}