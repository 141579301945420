.title_style {
  text-align: center;
  letter-spacing: 0px;
  color: #2B303E;
  opacity: 1;
}

.faqs {
  text-align: left;
  font: normal normal bold 20px/25px Roboto;
  letter-spacing: 0px;
  color: #2B303E;
  opacity: 1;
  padding-left: 30px;
  padding-top: 17px;
  padding-bottom: 17px;
  cursor: pointer;
}

.faqs.highlighted {
  background-color: #FF3535;
  color: white;
  /* Set the font color to white when the question box is active */
}

.faqs_box {
  border: 1px solid #AEAEAE;
  border-radius: 10px;
  opacity: 1;
  margin-top: 20px;
}

.faqs_box:hover {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #00000033;
  border: 1px solid #FF3535;
  border-radius: 10px;
  opacity: 1;

}

.faqs_box.highlighted {
  /* Style for the question part when it's clicked (highlighted) */
  background-color: #FF3535;
}

.white-text {
  color: white;
  /* Set the font color to white for the active state */
}

.answer {
  /* Style for the message part */
  display: none;
  /* Hide the message by default */

}

.faqs_box.highlighted .answer {
  /* Show the message when the box is clicked and highlighted */
  display: block;
  background-color: #FFFFFF;
  padding-left: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 40px;
  text-align: left;
  font: normal normal normal 20px/26px Roboto;
  letter-spacing: 0px;
  color: #121212;
  opacity: 1;
}

.lastBox {
  margin-bottom: 60px;
}


/* mobile devices */
@media only screen and (max-width:767px) {
  .faq-header-container {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .faq-header-img {
    width: 100%;
  }

  .faq-top-container {
    padding: 20px 0px 20px 0px;
    text-align: center;
  }

  .heading-font {
    font-size: 7px !important;
  }

  .knowledgeBase-header-container {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .Tribe-container {
    margin: 40px 0px 40px;
  }

  .Tribe-header-img {
    background: transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%);
    height: 74px;
  }

  .Tribe-top-container {
    margin: 0px 0px 20px !important;
  }
  .Tribe-para{
    padding-top: 5px;
    /* padding-left: 5px; */
  }

  .Tribe-end-container {
    margin-top: 19px;
  }
}


/* tablet devices */
@media only screen and (min-width:768px) {

  .faq-header-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .faq-header-img {
    width: 100%;
  }

  .faq-top-container {
    padding: 25px 0px 25px 0px;
    text-align: center;
  }

  .knowledgeBase-header-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .Tribe-container {
    margin: 40px 0px 40px;
  }

  .Tribe-header-img {
    background: transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%);

    height: 200px;
  }

  .Tribe-top-container {
    margin: 0px 0px 25px !important;
  }
  .Tribe-para{
    padding-top: 7px;
    /* padding-left: 25px; */
  }

  .Tribe-end-container {
    margin-top: 20px;
  }
}

/* laptop devices */
@media only screen and (min-width:1000px) {
  .faq-header-container {
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95% !important;
  }

  .faq-header-img {
    width: 511px;
    height: 200px;
  }

  .faq-top-container {
    padding: 30px 0px 30px 0px;
    text-align: center;
  }

  .knowledgeBase-header-container {
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95% !important;
  }

  .Tribe-container {
    margin: 50px 0px 50px;
  }

  .Tribe-header-img {
    background: transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%);

    height: 200px;
  }

  .Tribe-top-container {
    margin: 0px 0px 30px !important;
  }
  .Tribe-para{
    padding-top: 15px;
    /* padding-left: 45px; */
  }
  .Tribe-end-container {
    margin-top: 25px;
  }
}

/* largedevices */
@media only screen and (min-width:1200px) {
  .faq-header-container {
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 95%;
    width: 1370px !important;
  }

  .faq-top-container {
    padding: 40px 40px;
    text-align: center;
  }

  .faq-top-container {
    padding: 35px 0px 35px 0px;
    text-align: center;
  }

  .knowledgeBase-header-container {
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 95%;
    width: 1370px !important;
  }


}