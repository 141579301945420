/* body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@font-face {
    font-family: 'Trajan Pro Regular';

    src: local('Trajan Pro Regular'), url('../../../../../fonts/TrajanPro-Regular.woff') format('woff');
}


@font-face {
    font-family: 'Trajan Pro Bold';

    src: local('Trajan Pro Bold'), url('../../../../../fonts/TrajanPro-Regular.woff') format('woff');
} */

.services {
    padding-top: 20px;
    padding-bottom: 35px;
    background: #FAFAFB 0% 0% no-repeat padding-box;
}

.events {
    padding-top: 20px;
    padding-bottom: 20px;
}


.card-header {
    display: flex;
    justify-content: space-evenly;
}

.services-card {
    box-shadow: 0px 2px 10px #00000026 !important;
    border-radius: 10px !important;
    opacity: 1;
    padding: 10px;
    width: 350px !important;
}

.card-title {
    text-align: center;
}

.card-content {
    text-align: center;
    letter-spacing: 0px;
    color: black;
    opacity: 1;
}

.read-more {

    text-decoration: none;
    letter-spacing: 0px;
    color: #FF3535;
    opacity: 1;
}

.read-more:hover {
    border-bottom: 3px solid #ff3535;
}

/* .slider-icon button[aria-label="Next"],
.slider-icon button[aria-label="Previous"] {
    background: transparent;
    width: 170px;
    height: 170px;
    top: 25% !important;
} */

.react-multi-carousel-list {
    padding-bottom: 20px;
    position: inherit !important;
}

.event-card {
    box-shadow: 0px 2px 10px #00000026 !important;
    border-radius: 10px !important;
    opacity: 1;
    width: 350px !important;
    background-color: #ffffff !important;
}

.event-hr {
    border: 2px solid #FF3535 !important;
    opacity: 1;
}

.list-component {
    padding-left: 0px !important;
    padding-top: 10px !important;
}

.card-content-wrapper {
    display: inline-block;
    margin-top: 20px;
    height: 80px;
    overflow: hidden !important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: black;

}

.li-icon-alignment li {
    padding-right: 20px !important;
}

.css-46bh2p-MuiCardContent-root {

    padding: 20px !important;
}

.plan-card {
    box-shadow: 0px 2px 10px #00000026 !important;
    border-radius: 10px !important;
    opacity: 1;
    margin-right: 20px;
    width: 540px;
    height: 400px;
}
.home-container-services {
    padding-right: 15px;
    padding-left: 15px;
}
/*mobile devices*/
@media only screen and (max-width: 767px) {
    .alignment-style {
        margin-left: 212px !important;
        margin-top: 100px;
    }
    .home-container-services{
    justify-content: center;
    margin: auto;
    padding: 0px;
    width: 100%;
    }


    .services {
        /* padding-top: 20px;
        padding-bottom: 40px; */
        background: #FAFAFB 0% 0% no-repeat padding-box;
    }

    .card-header {
        display: flex;
        justify-content: space-evenly;
    }

    .services-card {
        box-shadow: 0px 2px 10px #00000026 !important;
        border-radius: 10px !important;
        opacity: 1;
        padding: 10px;
        width: 250px !important;
        /* margin-left: 8% */
        margin :0px auto !important
    }

    .card-title {
        text-align: center;
    }

    .card-content {
        text-align: center;
        letter-spacing: 0px;
        color: black;
        opacity: 1;
    }

    .read-more {

        text-decoration: none;
        letter-spacing: 0px;
        color: #FF3535;
        opacity: 1;
    }

    .read-more:hover {
        border-bottom: 3px solid #ff3535;
    }

    /* .slider-icon button[aria-label="Next"],
    .slider-icon button[aria-label="Previous"] {
        background: transparent;
        width: 170px;
        height: 170px;
        top: 25% !important;
    } */

    .react-multi-carousel-list {
        padding-bottom: 20px;
        position: inherit !important;
    }

    .event-card {
        box-shadow: 0px 2px 10px #00000026 !important;
        border-radius: 8px !important;
        opacity: 1;
        width: 250px !important;
        background-color: #ffffff !important;
        margin-left: 0px auto !important;
    }

    .event-hr {
        border: 2px solid #FF3535 !important;
        opacity: 1;
    }

    .list-component {
        padding-left: 0px !important;
        padding-top: 10px !important;
    }

    .card-content-wrapper {
        display: inline-block;
        height: 80px;
        margin-top: 20px;
        overflow: hidden !important;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: black;

    }

    .li-icon-alignment li {
        padding-right: 20px !important;
    }

    .css-46bh2p-MuiCardContent-root {

        padding: 20px !important;
    }

    .plan-card {
        box-shadow: 0px 2px 10px #00000026 !important;
        border-radius: 10px !important;
        opacity: 1;
        margin-right: 20px;
        width: 540px;
        height: 400px;
    }

    /* .primary-font-bold1{ 
        font-style: normal !important;
        font-variant: normal !important;
        font-size: 16px !important;
        font-family: Roboto !important;
        font-weight: 700 !important;
        line-height: 20px !important;
        font-family: "Roboto","Helvetica","Arial",sans-serif !important;
      } */
    /* .primary-font-normal-4{
        font-style: normal !important;
        font-variant: normal !important;
        font-size: 11px !important;
        font-family: Roboto !important;
        font-weight: 400 !important;
        line-height: 21px !important;
    }

    .primary-font-normal-8 {
        font-style: normal !important;
        font-variant: normal !important;
        font-size: 40px !important;
        font-family: Roboto !important;
        font-weight: 500 !important;
        line-height: 50px !important;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    }

    .primary-font-bold-heading {
        font-style: normal !important;
        font-variant: normal !important;
        font-size: 25px !important;
        font-family: Roboto !important;
        font-weight: 700 !important;
        line-height: 66px !important;
        margin-right: '20px';
        font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    } */
}

/* tablet */
@media only screen and (min-width: 768px) {
    .alignment-style {
        margin-left: 212px !important;
        margin-top: 100px;
    }

    .services {
        /* padding-top: 20px;
       padding-bottom: 40px; */
        background: #FAFAFB 0% 0% no-repeat padding-box;
    }

    .card-header {
        display: flex;
        justify-content: space-evenly;
    }

    .services-card {
        box-shadow: 0px 2px 10px #00000026 !important;
        border-radius: 10px !important;
        opacity: 1;
        padding: 10px;
        width: 320px !important;
       
    }
        .home-container-services {
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
            padding-left: 20px;
            padding-right:20px;
            width: 100%;
        }

    .card-title {
        text-align: center;
    }

    .card-content {
        text-align: center;
        letter-spacing: 0px;
        color: black;
        opacity: 1;
    }

    .read-more {

        text-decoration: none;
        letter-spacing: 0px;
        color: #FF3535;
        opacity: 1;
    }

    .read-more:hover {
        border-bottom: 3px solid #ff3535;
    }

    /* .slider-icon button[aria-label="Next"],
   .slider-icon button[aria-label="Previous"] {
       background: transparent;
       width: 170px;
       height: 170px;
       top: 25% !important;
   } */

    .react-multi-carousel-list {
        padding-bottom: 20px;
        position: inherit !important;
    }

    .event-card {
        box-shadow: 0px 2px 10px #00000026 !important;
        border-radius: 8px !important;
        opacity: 1;
        width: 320px !important;
        background-color: #ffffff !important;
    }

    .event-hr {
        border: 2px solid #FF3535 !important;
        opacity: 1;
    }

    .list-component {
        padding-left: 0px !important;
        padding-top: 10px !important;
    }

    .card-content-wrapper {
        display: inline-block;
        height: 80px;
        margin-top: 20px;
        overflow: hidden !important;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: black;

    }

    .li-icon-alignment li {
        padding-right: 20px !important;
    }

    .css-46bh2p-MuiCardContent-root {

        padding: 20px !important;
    }

    .plan-card {
        box-shadow: 0px 2px 10px #00000026 !important;
        border-radius: 10px !important;
        opacity: 1;
        margin-right: 20px;
        width: 540px;
        height: 400px;
    }

    /* .primary-font-bold1{ 
       font-style: normal !important;
       font-variant: normal !important;
       font-size: 16px !important;
       font-family: Roboto !important;
       font-weight: 700 !important;
       line-height: 20px !important;
       font-family: "Roboto","Helvetica","Arial",sans-serif !important;
     } */
    /* .primary-font-normal-4{
       font-style: normal !important;
       font-variant: normal !important;
       font-size: 11px !important;
       font-family: Roboto !important;
       font-weight: 400 !important;
       line-height: 21px !important;
   }

   .primary-font-normal-8 {
       font-style: normal !important;
       font-variant: normal !important;
       font-size: 40px !important;
       font-family: Roboto !important;
       font-weight: 500 !important;
       line-height: 50px !important;
       font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
   }

   .primary-font-bold-heading {
       font-style: normal !important;
       font-variant: normal !important;
       font-size: 25px !important;
       font-family: Roboto !important;
       font-weight: 700 !important;
       line-height: 66px !important;
       margin-right: '20px';
       font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
   } */
}

/* laptop devices */
@media only screen and (min-width:1000px) and (max-width:1199px) {
    .services {
        padding-top: 20px;
        padding-bottom: 40px;
        background: #FAFAFB 0% 0% no-repeat padding-box;
    }
    .events {
        padding-top: 20px;
        padding-bottom: 40px;
    }

    .card-header {
        display: flex;
        justify-content: space-evenly;
    }

    .services-card {
        box-shadow: 0px 2px 10px #00000026 !important;
        border-radius: 10px !important;
        opacity: 1;
        padding:  5px !important;
        width: 280px !important;
        margin-right: 20px !important;
        margin-left: 16px !important;
    }

    
    .event-card {
         box-shadow: 0px 2px 10px #00000026 !important;  
        border-radius: 8px !important;
        opacity: 1;
        width: 280px !important ;
        background-color: #ffffff !important;
        margin-right: 20px !important;
        margin-left: 16px !important;
      }

    .card-title {
        text-align: center;
    }

    .card-content {
        text-align: center;
        letter-spacing: 0px;
        color: black;
        opacity: 1;
    }

    .read-more {

        text-decoration: none;
        letter-spacing: 0px;
        color: #FF3535;
        opacity: 1;
    }

    .read-more:hover {
        border-bottom: 3px solid #ff3535;
    }
    .home-container-services{
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        padding-left: 0;
        padding-right: 0;
        width: 95%;
        }

    /* .slider-icon button[aria-label="Next"],
    .slider-icon button[aria-label="Previous"] {
        background: transparent;
        width: 170px;
        height: 170px;
        top: 25% !important;
    } */

    .react-multi-carousel-list {
        padding-bottom: 20px;
        position: inherit !important;
    }


    .event-hr {
        border: 2px solid #FF3535 !important;
        opacity: 1;
    }

    .list-component {
        padding-left: 0px !important;
        padding-top: 10px !important;
    }

    .card-content-wrapper {
        display: inline-block;
        height: 80px;
        margin-top: 20px;
        overflow: hidden !important;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: black;

    }

    .li-icon-alignment li {
        padding-right: 20px !important;
    }

    .css-46bh2p-MuiCardContent-root {

        padding: 20px !important;
    }

    .plan-card {
        box-shadow: 0px 2px 10px #00000026 !important;
        border-radius: 10px !important;
        opacity: 1;
        margin-right: 20px;
        width: 540px;
        height: 400px;
    }

    /*   .primary-font-bold1 {
        font-style: normal !important;
        font-variant: normal !important;
        font-size: 16px !important;
        font-family: Roboto !important;
        font-weight: 700 !important;
        line-height: 20px !important;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    }

    .primary-font-normal-4 {
        font-style: normal !important;
        font-variant: normal !important;
        font-size: 11px !important;
        font-family: Roboto !important;
        font-weight: 400 !important;
        line-height: 21px !important;
    }

    .primary-font-normal-8 {
        font-style: normal !important;
        font-variant: normal !important;
        font-size: 40px !important;
        font-family: Roboto !important;
        font-weight: 500 !important;
        line-height: 50px !important;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    }

    .primary-font-bold-heading {
        font-style: normal !important;
        font-variant: normal !important;
        font-size: 25px !important;
        font-family: Roboto !important;
        font-weight: 700 !important;
        line-height: 66px !important;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    } */

}
/* large devices */
@media only screen and (min-width:1200px) 
{
    .services {
        padding-top: 20px;
        padding-bottom: 50px;
        background: #FAFAFB 0% 0% no-repeat padding-box;
    }
    .events {
        padding-top: 20px;
        padding-bottom: 40px;
    }
     .home-container-services {
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        width: 1370px;
    }

    .card-header {
        display: flex;
        justify-content: space-evenly;
    }

    .services-card {
        box-shadow: 0px 2px 10px #00000026 !important;
        border-radius: 10px !important;
        opacity: 1;
        /* padding: 10px; */
        width: 350px !important;
        margin-left: 20px !important;  
        margin-right: 20px !important;
    }

    .event-card {
      box-shadow: 0px 2px 10px #00000026 !important;  
        border-radius: 8px !important;
        opacity: 1;
        width: 350px !important;
        /* padding: 10px; */
        background-color: #ffffff !important;
        margin-left: 20px !important;  
        margin-right: 20px !important;
    }

    .card-title {
        text-align: center;
    }

    .card-content {
        text-align: center;
        letter-spacing: 0px;
        color: black;
        opacity: 1;
    }

    .read-more {

        text-decoration: none;
        letter-spacing: 0px;
        color: #FF3535;
        opacity: 1;
    }

    .read-more:hover {
        border-bottom: 3px solid #ff3535;
    }

    /* .slider-icon button[aria-label="Next"],
    .slider-icon button[aria-label="Previous"] {
        background: transparent;
        width: 170px;
        height: 170px;
        top: 25% !important;
    } */

    .react-multi-carousel-list {
        padding-bottom: 20px;
        position: inherit !important;
    }


    .event-hr {
        border: 2px solid #FF3535 !important;
        opacity: 1;
    }

    .list-component {
        padding-left: 0px !important;
        padding-top: 10px !important;
    }

    .card-content-wrapper {
        display: inline-block;
        height: 80px;
        margin-top: 20px;
        overflow: hidden !important;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: black;

    }

    .li-icon-alignment li {
        padding-right: 20px !important;
    }

    .css-46bh2p-MuiCardContent-root {

        padding: 20px !important;
    }

    .plan-card {
        box-shadow: 0px 2px 10px #00000026 !important;
        border-radius: 10px !important;
        opacity: 1;
        margin-right: 20px;
        width: 540px;
        height: 400px;
    }

    /*   .primary-font-bold1 {
        font-style: normal !important;
        font-variant: normal !important;
        font-size: 16px !important;
        font-family: Roboto !important;
        font-weight: 700 !important;
        line-height: 20px !important;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    }

    .primary-font-normal-4 {
        font-style: normal !important;
        font-variant: normal !important;
        font-size: 11px !important;
        font-family: Roboto !important;
        font-weight: 400 !important;
        line-height: 21px !important;
    }

    .primary-font-normal-8 {
        font-style: normal !important;
        font-variant: normal !important;
        font-size: 40px !important;
        font-family: Roboto !important;
        font-weight: 500 !important;
        line-height: 50px !important;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    }

    .primary-font-bold-heading {
        font-style: normal !important;
        font-variant: normal !important;
        font-size: 25px !important;
        font-family: Roboto !important;
        font-weight: 700 !important;
        line-height: 66px !important;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    } */
   
}

