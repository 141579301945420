@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
div,body {
margin: 0px 0px 0px 0px;

padding: 0px 0px 0px 0px;
    font-family:'Open Sans',  Arial, Gotham, Helvetica, sans-serif;
}
div{box-sizing: border-box;}

.head_line{margin-bottom: 5px; display: block;position: relative;}
.head_line h3{font-size: 36px; text-align: center; position: relative; display: block; margin: 0px; padding: 0px;}
.head_line h3:after, .head_line h3:before{content: ''; position: absolute; width: 180px; height: 2px; background: #B5B5B5; top: 50%;}
.head_line h3:after{left: 10%;}
.head_line h3:before{right: 10%;}

 .sub_headline{margin-bottom: 5px; display: block;position: relative; margin-top: -15px;}
 .sub_headline h4{font-size: 18px; text-align: center; position: relative; display: block; margin: 0px; padding: 0px;color: #636363;}
.verified_action{display: block; text-align: center;}
.icon_box_001{font-size: 14px;color: #636363;}
.icon_box_001 svg{width: 14px; height: 14px; line-height: 0px;color: #636363;}
.icon_box_001 a{text-decoration: none; color: #636363;}
.main_wrapper1{display: block; width: 100%; position: relative;}
.main_content{
    margin: 0 auto !important;
    padding: 0px; 
    width: 768px;
    display:block;
    box-sizing: border-box;
}

@media only screen and (max-width: 767px) {
    .head_line h3:before, .head_line h3:after {
        width: 10%;
    }
    .badges {
        margin-bottom: 20px;
    }
    .head_line h3 {
        font-size: 20px;
    }
    .main_content {
        width: 60%;
    }
    .badges img {
        max-width: 100% !important;
    }
}