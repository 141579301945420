index



body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Trajan Pro Regular';
  
  src: local('Trajan Pro Regular'), url('./fonts/TrajanPro-Regular.woff') format('woff');
  }
  

@font-face {
  font-family: 'Trajan Pro Bold';

  src: local('Trajan Pro Bold'), url('./fonts/TrajanPro-Bold.woff') format('woff');
  }
  /*mobile devices*/
  @media only screen and (max-width: 767px) {
    .alignment-style{
      margin-left: 212px !important;
      margin-top: 100px;
    }
    .primary-font-bold{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 22px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 30px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold1{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 20px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold2{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 24px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 26px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold3{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 24px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold4{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 26px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold5{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 18px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 29px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold6{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 39px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 47px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold7{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Trajan Pro Bold !important;
      font-weight: 400 !important;
      line-height: 25px !important;
     
    }
    .primary-font-bold8{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 25px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 35px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    
    .primary-font-bold9{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 23px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 26px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    
    .primary-font-bold10{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 26px !important ;
      font-family: Trajan Pro !important ;
      font-weight: 700 !important;
      line-height: 43px !important;
     
    }
    .primary-font-bold11{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 16px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 24px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    
    .primary-font-bold12{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 22px !important ;
      font-family: Trajan Pro !important ;
      font-weight: 700 !important;
      line-height: 43px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold13{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 20px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 29px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold14{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 16px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 32px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold15{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 26px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
     .primary-font-bold16{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 11px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 12px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold17{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 19px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold18{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 11px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 14px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold19{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 12px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 9px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold20{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 20px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 28px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold21{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 24px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold-22{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:11px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 23px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold23{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 23px !important;
     
    }
    .primary-font-bold-heading{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 25px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 35px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-default-heading{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 26px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 45px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-default-heading1{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 26px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 45px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    
    .primary-font-normal-plan-heading{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 26px !important ;
      font-family: Roboto !important ;
      font-weight: 300 !important;
      line-height: 106px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold-read-more{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 26px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    
    .primary-font-medium{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size:20px !important ;
      font-family: Roboto !important ;
      font-weight: 500 !important;
      line-height: 24px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-medium-1{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size:16px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 30px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-text{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 16px !important ;
      font-family: Roboto !important ;
      line-height: 26px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-medium-2{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 500 !important;
      line-height: 24px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-medium-3{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 8px !important ;
      font-family: Roboto !important ;
      font-weight: 500 !important;
      line-height: 12px !important;
      /* font-family: "Roboto","Helvetica","Arial",sans-serif !important; */
    }
    .primary-font-medium-4{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 500 !important;
      line-height: 24px !important;
      /* font-family: "Roboto","Helvetica","Arial",sans-serif !important; */
    }
    .primary-font-medium-5{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 500 !important;
      line-height: 26px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-medium-6{ 
      font-style: italic !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family:Roboto !important ;
      font-weight: 500 !important;
      line-height: 26px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-medium-7{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 500 !important;
      line-height: 24px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-medium-8{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 12px !important ;
      font-family: Roboto !important ;
      font-weight: 500 !important;
      line-height: 24px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-medium-9{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 9px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 14px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-medium-10{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 9px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 15px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-medium-11{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 12px !important ;
      font-family: Roboto !important ;
      font-weight: 500 !important;
      line-height: 27px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-medium-12{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 16px !important ;
      font-family: Roboto !important ;
      font-weight: 500 !important;
      line-height: 22px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-medium-13{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 16px !important ;
      font-family: Roboto !important ;
      font-weight: 500 !important;
      line-height: 22px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-1{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:14px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 42px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    
    .primary-font-normal-2{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:12px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 26px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    
    .primary-font-normal-3{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 24px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    
    .primary-font-normal-4{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 11px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 21px !important;
    }
    .primary-font-normal-5{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:14px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 25px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-6{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:12px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 26px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-7{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:10px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 19px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-8{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 46px !important ;
      font-family: Roboto !important ;
      font-weight: 300 !important;
      line-height: 70px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-9{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 26px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    
    .primary-font-normal-10{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:10px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 10px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-11{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:14px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 25px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    
    }
  
    .primary-font-normal-12{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:10px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 30px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
  
    .primary-font-normal-13{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 16px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 24px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-14{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 22ppx !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-15{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 19px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-16{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 18px !important ;
      font-family: Roboto !important ;
      font-weight: 300 !important;
      line-height: 33px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-17{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 18px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 26px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-18{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 14px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 28px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-19{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:14px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 26px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-20{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:11px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 16px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-21{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:11px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 19px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-22{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:19px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 29px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
  
    .error-message {
      float:right; 
      /* margin-left: 14px !important; 
      margin-right: 3% !important; */
      font-size: 12px !important;
      color: #FF3535 !important;
    }
    .event-calender-font{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 11px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 21px !important;
    }
    .primary-font-bold-heading1{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 35px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 66px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
      margin-right: 55px !important;
    }

    .primary-font-bold-disater{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 16px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 29px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-bold-plan{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 18px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 20px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
      margin-bottom: 5px;
    }
    .primary-font-bold-blogs{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 16px !important ;
      font-family: Trajan Pro !important ;
      font-weight: 700 !important;
      line-height: 30px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .list{  
      /* margin-bottom: 15px; */
      font-family: Roboto !important;
    }
    .bullet-list {
      list-style-type: none;
      padding-left: 0;
      font-family: Roboto !important;
      color: #000000;
      margin: 0px !important;
    }
    .MuiAccordionDetails-root{
      padding-bottom: 5px !important;
    }
  }
/* Small devices such as large phones (640px and up) */
/* @media only screen and (min-width: 40em) {
  .alignment-style{
    margin-left: 212px !important;
    margin-top: 100px;
  }
  .primary-font-bold{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 42px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 60px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold1{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold2{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 24px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold3{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 24px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 37px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold4{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold5{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold6{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 39px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 47px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold7{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Trajan Pro Bold !important;
    font-weight: 400 !important;
    line-height: 43px !important;
   
  }
  .primary-font-bold8{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 36px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 53px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-bold9{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 28px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-bold10{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Trajan Pro !important ;
    font-weight: 700 !important;
    line-height: 43px !important;
   
  }
  .primary-font-bold11{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-bold12{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-family: Trajan Pro !important ;
    font-weight: 700 !important;
    line-height: 43px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold13{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 32px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 53px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold14{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 32px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold15{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
   .primary-font-bold16{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 12px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 14px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold17{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold18{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 42px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 60px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold19{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold20{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 24px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 32px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
   .primary-font-bold21{ 
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 24px !important ;
      font-family: Roboto !important ;
      font-weight: 700 !important;
      line-height: 45px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
     .primary-font-bold-22{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 35px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-default-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 45px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-default-heading1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 45px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-plan-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 300 !important;
    line-height: 106px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-read-more{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-medium{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size:20px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-1{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size:20px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
 
  .primary-font-normal-text{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-2{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 33px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-3{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-4{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 34px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-5{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-6{ 
    font-style: italic !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family:Roboto !important ;
    font-weight: 500 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-7{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-8{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-9{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 12px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 14px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-10{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 33px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-11{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
    .primary-font-medium-12{
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 18px !important ;
      font-family: Roboto !important ;
      font-weight: 500 !important;
      line-height: 22px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
  .primary-font-normal-1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:14px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 42px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-2{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:12px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-3{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-4{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .primary-font-normal-5{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-6{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:12px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-7{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-8{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 46px !important ;
    font-family: Roboto !important ;
    font-weight: 300 !important;
    line-height: 70px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-9{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-10{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:12px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-11{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:10px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    color: #000000;
  }

  .primary-font-normal-12{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .primary-font-normal-13{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-14{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 10px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-15{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 10px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 19px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-16{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 28px !important ;
    font-family: Roboto !important ;
    font-weight: 300 !important;
    line-height: 43px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-17{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-18{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
   .primary-font-normal-19{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:14px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 26px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
     .primary-font-normal-20{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:14px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 27px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
    .primary-font-normal-21{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:12px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-22{
      font-style: normal !important;
      font-variant: normal !important;
      font-size:32px !important ;
      font-family: Roboto !important ;
      font-weight: 400 !important;
      line-height: 38px !important;
      font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    }
  .error-message {
    float:right; 
    margin-right: 20px !important; 
    font-size: 12px !important;
    color: #FF3535 !important;
  }
  .event-calender-font{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .primary-font-bold-heading1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 35px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-disater{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
} */
/* Medium devices such as tablets (768px and up) */
@media only screen and (min-width: 768px) {
  .alignment-style{
    margin-left: 212px !important;
    margin-top: 100px;
  }
  .primary-font-bold{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 42px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 60px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold1{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 20px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold2{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 24px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold3{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 24px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 37px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold4{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold5{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold6{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 39px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 47px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold7{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Trajan Pro Bold !important;
    font-weight: 400 !important;
    line-height: 27px !important;
   
  }
  .primary-font-bold8{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 27px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 37px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-bold9{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 28px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-bold10{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Trajan Pro !important ;
    font-weight: 700 !important;
    line-height: 43px !important;
   
  }
  .primary-font-bold11{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold12{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Trajan Pro !important ;
    font-weight: 700 !important;
    line-height: 43px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .primary-font-bold13{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 36px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 53px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold14{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 32px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold15{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold16{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 17px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 34px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold17{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold18{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 32px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold19{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 20px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold20{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 24px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 32px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold21{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 19px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 41px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-22{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold23{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
   
  }
  
  .primary-font-bold-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 35px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-default-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 45px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-default-heading1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 45px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-plan-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 300 !important;
    line-height: 106px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-read-more{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-medium{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size:20px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-1{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .primary-font-normal-text{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-2{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 33px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-3{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 30px !important;
    /* font-family: "Roboto","Helvetica","Arial",sans-serif !important; */
  }
  .primary-font-medium-4{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 28px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 34px !important;
    /* font-family: "Roboto","Helvetica","Arial",sans-serif !important; */
  }
  .primary-font-medium-5{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-6{ 
    font-style: italic !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family:Roboto !important ;
    font-weight: 500 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-7{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-8{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-9{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 12px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 12px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-10{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 17px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  } 
   .primary-font-medium-11{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  } 
   .primary-font-medium-12{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 22px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-13{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 22px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:12px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 39px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-2{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:12px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-3{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-4{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .primary-font-normal-5{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-6{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:12px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-7{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-8{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 46px !important ;
    font-family: Roboto !important ;
    font-weight: 300 !important;
    line-height: 70px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-9{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-10{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:12px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .primary-font-normal-11{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    color: #000000;
  }

  .primary-font-normal-12{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:13px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .primary-font-normal-13{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }  
  .primary-font-normal-14{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-15{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 10px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 19px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-16{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 28px !important ;
    font-family: Roboto !important ;
    font-weight: 300 !important;
    line-height: 43px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-17{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-18{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-19{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-20{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 27px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-21{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-22{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:32px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 38px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .error-message {
    float:right; 
    margin-right: 20px !important; 
    font-size: 12px !important;
    color: #FF3535 !important;
  }
  .event-calender-font{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 21px !important; 
  }
  .primary-font-bold-heading1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 35px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-disater{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .events-grid{
    margin-left: 90px !important;
  }
  .primary-font-bold-plan{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-blogs{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Trajan Pro !important ;
    font-weight: 700 !important;
    line-height: 43px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-blogs-tablet{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 20px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  }

/* Largest devices such as laptops (1024px and up) */
@media only screen and (min-width: 1000px) {
  .alignment-style{
    margin-left: 287px !important; 
    margin-top: 100px;
  }
  .primary-font-bold{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 42px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 60px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold1{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold2{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 24px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold3{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 24px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 37px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold4{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold5{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold6{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 39px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 47px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold7{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Trajan Pro Bold !important;
    font-weight: 400 !important;
    line-height: 43px !important;
   
  }
  .primary-font-bold8{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 36px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 53px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-bold9{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 28px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-bold10{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Trajan Pro !important ;
    font-weight: 700 !important;
    line-height: 43px !important;
   
  }

  .primary-font-bold11{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-bold12{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 30px !important ;
    font-family: Trajan Pro !important ;
    font-weight: 700 !important;
    line-height: 43px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
.primary-font-bold13{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 40px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 53px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.primary-font-bold14{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 24px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 32px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold15{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 16px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold16{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 17px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 34px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold17{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 16px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 30px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold18{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size:38px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 49px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold19{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 22px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold20{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 24px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 32px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold21{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 19px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 45px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold-22{
  font-style: normal !important;
  font-variant: normal !important;
  font-size:15px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 29px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold23{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 26px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 38px !important;
 
}
  .primary-font-bold-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 35px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-default-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 45px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-default-heading1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 45px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-plan-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 300 !important;
    line-height: 106px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-read-more{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-medium{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size:20px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-1{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size:18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
 
  .primary-font-normal-text{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-2{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 33px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-3{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 30px !important;
    /* font-family: "Roboto","Helvetica","Arial",sans-serif !important; */
  }
  .primary-font-medium-4{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 34px !important;
    /* font-family: "Roboto","Helvetica","Arial",sans-serif !important; */
  }
  .primary-font-medium-5{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-6{ 
    font-style: italic !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family:Roboto !important ;
    font-weight: 500 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-7{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .primary-font-medium-8{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-9{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 32px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-10{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 33px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-11{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-12{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 22px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-13{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 22px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:14px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 42px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-2{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-3{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-4{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .primary-font-normal-5{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-6{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:12px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-7{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:15px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-8{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 46px !important ;
    font-family: Roboto !important ;
    font-weight: 300 !important;
    line-height: 106px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-9{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-10{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:12px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .primary-font-normal-11{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    color: #000000;
  }

  .primary-font-normal-12{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:13px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .primary-font-normal-13{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-14{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-15{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 19px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-16{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 28px !important ;
    font-family: Roboto !important ;
    font-weight: 300 !important;
    line-height: 43px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-17{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-18{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-19{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-20{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 27px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-21{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:15px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-22{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:29px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 33px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .event-calender-font{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .primary-font-bold-heading1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 35px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .error-message {
    float:right; 
    margin-right: 20px !important; 
    font-size: 12px !important;
    color: #FF3535 !important;
  }
  .primary-font-bold-plan{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-blogs{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 24px !important ;
    font-family: Trajan Pro !important ;
    font-weight: 700 !important;
    line-height: 32px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
}

/* Extra Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 1200px) {

  .alignment-style{
    margin-left:  287px !important;
    margin-top: 100px;
  }

.primary-font-bold{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 42px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 60px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold1{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 20px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold2{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 24px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold3{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 24px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 37px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold4{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 20px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold5{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 18px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 29px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold6{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 39px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 47px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold7{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 26px !important ;
  font-family: Trajan Pro Bold !important ;
  font-weight: 400 !important;
  line-height: 43px !important;
 
}
.primary-font-bold8{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 36px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 53px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.primary-font-bold9{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 28px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.primary-font-bold10{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 26px !important ;
  font-family: Trajan Pro !important ;
  font-weight: 700 !important;
  line-height: 43px !important;
 
}

.primary-font-bold11{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 22px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 24px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold12{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 34px !important ;
  font-family: Trajan Pro !important ;
  font-weight: 700 !important;
  line-height: 43px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold13{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 40px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 53px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold14{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 24px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 32px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold15{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 16px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold16{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 22px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 34px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold17{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 16px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 30px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold18{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 26px !important ;
  font-family: Roboto !important ;
  font-weight: 500 !important;
  line-height: 38px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold19{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 22px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold20{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 24px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 32px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold21{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 19px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 41px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold-22{
  font-style: normal !important;
  font-variant: normal !important;
  font-size:15px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 29px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold23{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 26px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 40px !important;
 
}

.primary-font-bold-heading{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 35px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 66px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-default-heading{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 26px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 45px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-default-heading1{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 26px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 45px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.primary-font-normal-plan-heading{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 26px !important ;
  font-family: Roboto !important ;
  font-weight: 300 !important;
  line-height: 106px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold-read-more{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 14px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.primary-font-bold-read-more-1{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 18px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 26px !important;
  color: #ff3535 !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-medium{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size:20px !important ;
  font-family: Roboto !important ;
  font-weight: 500 !important;
  line-height: 24px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-medium-1{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size:18px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 30px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.primary-font-normal-text{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 16px !important ;
  font-family: Roboto !important ;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-medium-2{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 18px !important ;
  font-family: Roboto !important ;
  font-weight: 500 !important;
  line-height: 33px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-medium-3{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 18px !important ;
  font-family: Roboto !important ;
  font-weight: 500 !important;
  line-height: 30px !important;
  /* font-family: "Roboto","Helvetica","Arial",sans-serif !important; */
}
.primary-font-medium-4{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 22px !important ;
  font-family: Roboto !important ;
  font-weight: 500 !important;
  line-height: 34px !important;
  /* font-family: "Roboto","Helvetica","Arial",sans-serif !important; */
}
.primary-font-medium-5{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 14px !important ;
  font-family: Roboto !important ;
  font-weight: 500 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-medium-6{ 
  font-style: italic !important;
  font-variant: normal !important;
  font-size: 14px !important ;
  font-family:Roboto !important ;
  font-weight: 500 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-medium-7{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 22px !important ;
  font-family: Roboto !important ;
  font-weight: 500 !important;
  line-height: 24px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.primary-font-medium-8{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-9{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 32px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-10{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 33px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-11{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-12{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 22px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-13{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 22px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
.primary-font-normal-1{
  font-style: normal !important;
  font-variant: normal !important;
  font-size:14px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 42px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.primary-font-normal-2{
  font-style: normal !important;
  font-variant: normal !important;
  font-size:16px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 20px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.primary-font-normal-3{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 14px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 24px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.primary-font-normal-4{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 16px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 21px !important;
}
.primary-font-normal-5{
  font-style: normal !important;
  font-variant: normal !important;
  font-size:18px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 30px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-normal-6{
  font-style: normal !important;
  font-variant: normal !important;
  font-size:12px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-normal-7{
  font-style: normal !important;
  font-variant: normal !important;
  font-size:16px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-normal-8{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 46px !important ;
  font-family: Roboto !important ;
  font-weight: 300 !important;
  line-height: 106px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-normal-9{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 18px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.primary-font-normal-10{
  font-style: normal !important;
  font-variant: normal !important;
  font-size:12px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 30px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.primary-font-normal-11{
  font-style: normal !important;
  font-variant: normal !important;
  font-size:18px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 30px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  color: #000000;
}

.primary-font-normal-12{
  font-style: normal !important;
  font-variant: normal !important;
  font-size:13px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 30px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.primary-font-normal-13{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 16px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 24px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-normal-14{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 18px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-normal-15{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 14px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 19px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-normal-16{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 28px !important ;
  font-family: Roboto !important ;
  font-weight: 300 !important;
  line-height: 43px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-normal-17{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 18px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 26px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.error-message {
  float:right; 
  margin-right: 20px !important; 
  font-size: 12px !important;
  color: #FF3535 !important;
}
.event-calender-font{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 16px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 21px !important;
}
.primary-font-bold-heading1{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 35px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 66px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold-blogs{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 24px !important ;
  font-family: Trajan Pro !important ;
  font-weight: 700 !important;
  line-height: 32px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

}

/* Extra Extra Largest devices such as desktops (1920px and up) */
/* @media only screen and (min-width: 120em) {
  
  .primary-font-bold{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 46px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 60px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold1{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 19px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold2{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold3{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 28px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 37px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold4{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold5{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold6{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 39px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 47px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold7{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 30px !important ;
    font-family:Trajan Pro Bold !important;
    font-weight: 400 !important;
    line-height: 43px !important;
   
  }
  .primary-font-bold8{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 40px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 53px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-bold9{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 32px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-bold10{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 30px !important ;
    font-family: Trajan Pro !important ;
    font-weight: 700 !important;
    line-height: 43px !important;
   
  }

  .primary-font-bold11{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .primary-font-bold12{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 38px !important ;
    font-family: Trajan Pro !important ;
    font-weight: 700 !important;
    line-height: 43px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold13{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 44px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 53px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold14{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 28px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 32px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold15{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold16{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 34px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold17{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold18{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 42px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 60px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold19{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold20{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 24px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 32px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold21{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 24px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 45px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-22{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:12px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-22{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 50px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-default-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 30px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 45px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-default-heading1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 30px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 45px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-plan-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 80px !important ;
    font-family: Roboto !important ;
    font-weight: 300 !important;
    line-height: 106px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-read-more{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-medium{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size:18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-1{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size:18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .primary-font-normal-text{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-family: Roboto !important ;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-2{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 33px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-3{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 21px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-4{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 34px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-5{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-6{ 
    font-style: italic !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family:Roboto !important ;
    font-weight: 500 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-7{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-8{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-9{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 24px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 32px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-10{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 33px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-11{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-medium-12{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 500 !important;
    line-height: 22px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 42px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-2{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-3{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-4{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .primary-font-normal-5{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:14px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-6{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-7{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-8{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:80px !important ;
    font-family: Roboto !important ;
    font-weight: 300 !important;
    line-height: 106px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-9{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:20px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  
  .primary-font-normal-10{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:12px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .primary-font-normal-11{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:22px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    color: #000000;
  }

  .primary-font-normal-12{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:20px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .primary-font-normal-13{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-14{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-15{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 19px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-16{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 32px !important ;
    font-family: Roboto !important ;
    font-weight: 300 !important;
    line-height: 43px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-17{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-18{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 30px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .primary-font-normal-19{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-20{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 27px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-21{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-normal-22{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:32px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 38px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .error-message {
    float:right; 
    margin-right: 20px !important; 
    font-size: 12px !important;
    color: #FF3535 !important;
  }
  .event-calender-font{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .primary-font-bold-heading1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 35px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-plan{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-family: Roboto !important ;
    font-weight: 700 !important;
    line-height: 29px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

} */

  
  .alignment-style{
    margin-left: 287px ;
    margin-top: 120px !important;
  }
  
  
.bullet-list {
  list-style-type: none;
  padding-left: 0;
  font-family: Roboto !important;
  color: #000000;
}

/* .bullet-list li {
  font-size: 18px;
  font-family: Roboto !important;
  line-height: 24px !important;
  text-align: left;
  letter-spacing: 0.11px;
} */

.bullet-list li:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ff3535;
  margin-left: 18px;
 /* padding-top: px; */
 margin-top: 9px;
  margin-right: 18px;
}

.list{  
  margin-bottom: 15px;
  font-family: Roboto !important;
}



.event-card-1{ 
  border-radius: 10px !important;
  opacity: 1;
  height: 388px;
  border: 1px  solid #D8D8D8 !important;
}



.comment-btn {
  border-radius: 30px !important;
  background: #ff3535 !important ;
  color: #fff !important;
  opacity: 1;
  border-color: #ff3535 !important;
  text-transform: unset !important;
  width: 480px;
  height: 60px;
  font-size: 18px !important;
  letter-spacing: 0.11px;
  line-break: 24px;
  font-weight: 500 !important;
}
.comment-btn:hover {
  background: #ff3535 !important ;
  color: #fff !important;
}

/* .error-message {
  float:right; 
  margin-right: 20px !important; 
  font-size: 12px !important;
  color: #FF3535;
} */
.err-input {
  border: 1px solid #FF3535 !important;
  box-shadow: 0px 2px 10px #00000029 !important;
}

.disaster-tab-title{
  color: #FF3535 !important;
}

.formStyles::placeholder{
  font-family:  "Roboto","Helvetica","Arial";
}

.popup-container {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.popup-container::-webkit-scrollbar {
  display: none;
}
.primary-font-bold-disater{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 18px !important ;
  font-family: Roboto !important ;
  font-weight: 700 !important;
  line-height: 29px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.events-grid{
  margin-left: 90px !important;
}

