.AboutUS {
    margin: 0px 0px 20px 0px !important;
    text-align: center;
    letter-spacing: 0px;
    color: #2B303E;
    opacity: 1;
}

.Readmore_content1 {
    /* UI Properties */
    margin-left: 15%;
    margin-right: 15%;
    color: var(--unnamed-color-8b8b8b);
    text-align: center;
    font: normal normal normal 18px/30px Roboto;
    letter-spacing: 0px;
    color: #8B8B8B;
    opacity: 1;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}



.Readmore_link {
    color: var(--unnamed-color-40aeba);
    text-align: center;
    text-decoration: underline;
    letter-spacing: 0px;
    color: #40AEBA;
    opacity: 1;
}



.subheading1_Readmore_link {

    text-align: center;
    text-decoration: none;
    letter-spacing: 0px;
    color: #ff3535;
    opacity: 1;

}

.subheading1_Readmore_link:hover {
    border-bottom: 3px solid #ff3535;

}

.subheading2 {

    /* UI Properties */
    color: var(--unnamed-color-2b303e);
    text-align: left;
    font: normal normal bold 24px/32px Roboto;
    letter-spacing: 0px;
    color: #2B303E;
    opacity: 1;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.subheading2_content {

    /* UI Properties */
    color: var(--unnamed-color-8b8b8b);
    text-align: left;
    font: normal normal normal 18px/30px Roboto;
    letter-spacing: 0px;
    color: #8B8B8B;
    opacity: 1;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.subheading2_Readmore_link {

    /* UI Properties */
    color: var(--unnamed-color-40aeba);
    text-align: center;
    text-decoration: underline;
    letter-spacing: 0px;
    color: #40AEBA;
    opacity: 1;
}

.GridLayout {
    margin-left: 18%;
    margin-right: 18%;
}

.aboutUs_img {
    margin-top: -50px;
    border-radius: 10px;
}

.header-content {
    height: 200px;
    justify-content: center;
    align-items: center;
    background-color: #e9e9e9;
}

.brief-history {
    letter-spacing: 0.11px !important;
    padding-left: 30px;
    text-align: left;
}



.scroll-content1 {
    box-sizing: border-box;
    min-width: 350px;
    border-left: 2px solid #ff3535;
    padding-left: 50px;
    position: relative;
    z-index: 1;
}

.scroll-content1:first-child {
    margin-left: 200px;
}

.scroll-content1:last-child {
    margin-right: 200px;
}

.scroll-content1 .scroll-head {
    padding-top: 0;
    color: #ff3535;
    margin: 0;
}

.scroll-content2 .scroll-head {
    color: #ff3535;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 45px;
}

.scroll-content1 .scroll-info {
    margin-bottom: 0;
    padding-bottom: 45px;
}

.scroll-content2 .scroll-info {
    margin-bottom: 0 !important;
}

.scroll-content2 {
    box-sizing: border-box;
    min-width: 350px;
    border-left: 2px solid #ff3535;
    padding-left: 40px;
    height: fit-content;
    position: relative;
    z-index: 1;
}

.scroll-content2:first-child {
    margin-left: 150px;
}

.scroll-content2:last-child {
    margin-right: 200px;
}

.outer-circle {
    width: 27px;
    height: 27px;
    border-radius: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 8px #00000033;
}

.inner-circle {
    width: 17px;
    height: 17px;
    border-radius: 100%;
    background-color: #ff3535;
}

.top-box .circle-holder {
    position: absolute;
    left: 34.2%;
    bottom: -4.4%;
}

.bottom-box .circle-holder {
    position: absolute;
    left: 27.5%;
    top: -4%;
}

.top-box:last-child .scroll-content1 {
    border-left: none;
}

.top-box:last-child .circle-holder {
    display: none;
}

.pricing-btn {
    border-radius: 10px !important;
    background: #fff !important;
    color: #ff3535 !important;
    opacity: 1;
    border-color: #ff3535 !important;
    text-transform: unset !important;
    /* width: 195px; */
    height: 60px;
    font-size: 18px !important;
    font-weight: 500 !important;
}

.pricing-btn:hover {
    background: #ff3535 !important;
    color: #fff !important;
}

.pricing-btn1 {
    border-radius: 10px !important;
    background: #fff !important;
    color: #ff3535 !important;
    opacity: 1;
    border-color: #ff3535 !important;
    text-transform: unset !important;
    width: 150px;
    height: 40px;
    font-size: 18px !important;
    font-weight: 500 !important;
}

.pricing-btn1:hover {
    background: #ff3535 !important;
    color: #fff !important;
}

/* .slider-icon button[aria-label="Next"], .slider-icon button[aria-label="Previous"] {
        background: transparent;
        width: 170px;
        height: 170px;
        top: 25% !important;
    } */

.video-container {
    position: relative;
    /* left: 2%; */
}

.background-image {
    width: 380px;
    height: 178px;
}

.video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -56%);
    width: "630px" !important;
    height: "480px" !important;
}

/* .slider-icon button[aria-label="Next"]:hover, .slider-icon button[aria-label="Previous"]:hover{
        background: transparent !important;
    } */


/* mobile devices */
@media only screen and (max-width:767px) {
    .container-home-aboutus {
        margin: auto;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

    .topcontainer-home-aboutus {
        padding: 20px 0px 40px 0px;
    }

    .subheading1 {
        margin: 15px 0px 10px 0px !important;
        text-align: left;
        letter-spacing: 0px;
        color: #2B303E;
        opacity: 1;
    }

    .subheading1_content {

        margin: 5px 0px !important;
        text-align: left;
        color: #8B8B8B;
        opacity: 1;

    }

    .intro-Video-container {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        border-radius: 10px 10px 0px 0px ;
        box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16) !important;
        margin-top: 20px;
    }

    .intro-Video {


        position: absolute;
        width: 100% !important;
        height: 100% !important;
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;
      
        box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16) !important;


    }

    .readmore-leftalign {
        text-align: left
    }

    .appvideo-container{
        margin: auto;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

    .appvideo-top-container{
padding: 30px 0px 50px 0px;
    }
    .video-container-top{
        width: 100%;
        margin: 0px ;
    }
    .intro-app-Video-container {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16) !important;
        margin-top: 20px;
    }
 .intro-app-Video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;
        border-radius: 10px;
        box-shadow: 0 0 20px 0 rgba(63, 69, 81, 0.16) !important;
    }


}

/* tablet devices */
@media only screen and (min-width:768px) and (max-width:999px) {

    .container-home-aboutus {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

    .topcontainer-home-aboutus {
        padding: 40px 0px 40px 0px;
    }

    .subheading1 {
        margin: 15px 0px 10px 0px  !important;
        text-align: left;
        letter-spacing: 0px;
        color: #2B303E;
        opacity: 1;
    }

    .subheading1_content {

        margin: 5px 0px !important;
        text-align: left;
        color: #8B8B8B;
        opacity: 1;

    }

    .intro-Video-container {

        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        margin-top: 10px;
        border-radius: 10px 10px 0px 0px ;
        box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16);
    }


    .intro-Video {


        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        border-radius: 10px 10px 0px 0px ;
        box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16);
    }



    .readmore-leftalign {
        text-align: center !important
    }
.appvideo-container{
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;    
    }
    .appvideo-top-container{
        padding: 30px 0px 50px 0px;
            }
    .video-container-top{
        width: 100%;
        margin: 0px ;
    }
    .intro-app-Video-container {

        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        margin-top: 10px;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16);
    }


    .intro-app-Video {


        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16);
    }

}

/* laptop devices */
@media only screen and (min-width:1000px) {
    .container-home-aboutus {
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        padding-left: 0;
        padding-right: 0;
        width: 95%;
    }

    .topcontainer-home-aboutus {
        padding: 40px 0px 50px 0px;
    }

    .subheading1 {
        margin: 15px 0px 10px 0px  !important;
        text-align: left;
        letter-spacing: 0px;
        color: #2B303E;
        opacity: 1;
    }

    .subheading1_content {

        margin: 10px 0px !important;
        text-align: left;
        color: #8B8B8B;
        opacity: 1;

    }

    .intro-Video-container {
        position: relative;
        padding-bottom: 56.25%;
        width: 100%;
        border-radius: 10px 10px 0px 0px ;
        box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16) !important;
    }

    .intro-Video {

        position: absolute;
        width: 100% !important;
        height: 100% !important;
        top: 0;
        left: 0;
        border: none;
        padding: 0;
        margin: 0;
        border-radius: 10px 10px 0px 0px ;
        box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16) !important;


    }

    .readmore-leftalign {
        text-align: center !important
    }
    .appvideo-container{
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        padding-left: 0;
        padding-right: 0;
        width: 95%;  
    }
    .appvideo-top-container{
        padding: 40px 0px 60px 0px;
            }
    .video-container-top{
        width: 100%;
        margin: 0px 15% ;
    }
    .intro-app-Video-container {
        position: relative;
        padding-bottom: 56.25%;
        width: 100%;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16) !important;
    }

    .intro-app-Video {

        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: none;
        padding: 0;
        margin: 0;
        border-radius: 10px;
        box-shadow: 0 0 20px 0 rgba(63, 69, 81, 0.16) !important;


    }
}

/* largedevices */
@media only screen and (min-width:1200px) {
    .container-home-aboutus {
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 95%;
        width: 1370px;
    }

    .topcontainer-home-aboutus {
        padding: 40px 0px 50px 0px;
    }

    .subheading1 {
        margin: 15px 0px 10px 0px  !important;
        text-align: left;
        letter-spacing: 0px;
        color: #2B303E;
        opacity: 1;
    }

    .subheading1_content {

        margin: 10px 0px !important;
        text-align: left;
        color: #8B8B8B;
        opacity: 1;

    }

    .intro-Video-container {
        position: relative;
        padding-bottom: 56.25%;
        width: 100%;
        box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16) !important;
        border-radius: 10px 10px 0px 0px ;
    }

    .intro-Video {


        position: absolute;
        width: 100% !important;
        height: 100% !important;
        top: 0;
        left: 0;
        border: none;
        padding: 0;
        margin: 0;
        border-radius: 10px 10px 0px 0px ;
        box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16) !important;

    }

    .readmore-leftalign {
        text-align: center !important
    }
    .appvideo-container{
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 95%;
        width: 1370px;      
    }
    .appvideo-top-container{
        padding: 40px 0px 66px 0px;
            }
    .video-container-top{
        width: 100%;
        margin: 0px 15% ;
    }
    .intro-app-Video-container {
        position: relative;
        padding-bottom: 56.25%;
        width: 100%;
        box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16) !important;
        border-radius: 10px;

    }

    .intro-app-Video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: none;
        padding: 0;
        margin: 0;
        border-radius: 10px;
        box-shadow: 0 0 20px 0 rgba(63, 69, 81, 0.16) !important;

    }

}
