.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
  }

  .slick-carousal.slick-slide img {
    display: none !important;
}
 
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
 
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
  } 
.slick-prev,
.slick-next {
    display: none;
  }
  .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    /* height: 100px;  */
    outline: none;
  } 

  /* .slick-next{
    background: transparent;
  }
  .slick-prev{
    background: transparent;
  } */
  
  .slick-prev,
  .slick-next {
    background: transparent;
    /* font-size: 24px; */
    /* color: #555;  */
    border: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  
  .slick-prev {
    background-image: url('../../../../../assets/icons/left-arrow-blue-icon.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100px;
    width: 50px;
    left: -30px;
    font-size: 0px;
    z-index: 2;
    padding: 0px;
  }
  
  .slick-next {
    background-image: url('../../../../../assets/icons/right-arrow-blue-icon.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100px;
    width: 50px;
    right: -30px;
    font-size: 0px;
    z-index: 2;
    padding: 0px;
  }
  .custom-slider button.slick-prev {
    background-image: url('../../../../../assets/icons/left-arrow-white-icon.svg') !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 138px;
    width: 92px;
    left: 18px;
    font-size: 0px;
    z-index: 2;
  }
  
  .custom-slider button.slick-next{
    background-image: url('../../../../../assets/icons/right-arrow-white-icon.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 138px;
    width: 92px;
    right: 18px;
    font-size: 0px;
    z-index: 2;
  }

  .slick-prev:hover,
  .slick-next:hover {
   z-index: 200;
  } 

  
  /* .slick-dots {
    position: absolute;
    bottom: 10px;
    list-style: none;
    display: flex;

    justify-content: center;
    padding: 0;
    margin: 0;
  }
  
  .slick-dots li {
    margin: 0 5px;
  } */


/* Centering the active dot 
.slick-center {
  background-color: #fff;
  width: 30px; 
} */

/* Styling the dots container */
.slick-dots {
  display: flex !important;
  justify-content: center;
  list-style: none;
  padding: 0;
}

/* Styling individual dots */
.slick-dots li {
  margin: 0 5px; /* Adjust the spacing between dots */
  width: 10px; /* Adjust the width of the dots */
  height: 10px; /* Adjust the height of the dots */
  border-radius: 50%;
  cursor: pointer;
}
.slick-dots li:nth-child(n + 20) {
  display: none;
}

/* Styling the dot container when there are more dots than can fit on a single line */
.slick-dots.slick-dots-bottom {
  flex-wrap: wrap;
  margin-top: 10px; /* Adjust the margin as needed */
}


.slick-dots button {
  font-size: 0px !important;
  width: 20px; 
  height: 20px;
  border-radius: 50%; 
  background-color: #ff3535; 
  opacity: 0.5; 
  border: none; 
  outline: none; 
}
/* 
.slick-dots li:not(:nth-child(-n + 5)) {
  display: none; 
} */

.slick-dots li.slick-active button {
  opacity: 1; 
} 

  
  /* .slick-dots button:hover {
    color: #555;
  } */
  /* @media only screen and (max-width: 767px) {


  } */

  @media only screen and (max-width: 767px) {

      
  .slick-prev {
    background-image: url('../../../../../assets/icons/left-arrow-blue-icon.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 50px;
    width: 25px;
    left: 0px;
    font-size: 0px;
    z-index: 2;
  }
  
  .slick-next {
    background-image: url('../../../../../assets/icons/right-arrow-blue-icon.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 50px;
    width: 25px;
    right: 0px;
    font-size: 0px;
    z-index: 2;
  }
   
  .custom-slider button.slick-prev {
    background-image: url('../../../../../assets/icons/left-arrow-white-icon.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 50px;
    width: 15px;
    left: 10px;
    font-size: 0px;
    z-index: 2;
  }
  
  .custom-slider button.slick-next{
    background-image: url('../../../../../assets/icons/right-arrow-white-icon.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 50px;
    width: 15px;
    right: 10px;
    font-size: 0px;
    z-index: 2;
  }
  }

  /* table view */
  @media only screen and (min-width: 768px) and (max-width: 999px)  {

      
    .slick-prev {
      background-image: url('../../../../../assets/icons/left-arrow-blue-icon.svg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100px;
      width: 30px;
      left: 0px;
      font-size: 0px;
      z-index: 2;
    }
    
    .slick-next {
      background-image: url('../../../../../assets/icons/right-arrow-blue-icon.svg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100px;
      width: 30px;
      right: 0px;
      font-size: 0px;
      z-index: 2;
    }
      
  .custom-slider button.slick-prev {
    background-image: url('../../../../../assets/icons/left-arrow-white-icon.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 95px;
    width: 35px;
    left: 10px;
    font-size: 0px;
    z-index: 2;
  }
  
  .custom-slider button.slick-next{
    background-image: url('../../../../../assets/icons/right-arrow-white-icon.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 95px;
    width: 35px;
    right: 10px;
    font-size: 0px;
    z-index: 2;
  }
    }

    @media only screen and (min-width: 1000px) and (max-width:1200px) 
    {
      
    .custom-slider button.slick-prev {
      background-image: url('../../../../../assets/icons/left-arrow-white-icon.svg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 120px;
      width: 35px;
      left: 14px;
      font-size: 0px;
      z-index: 2;
    }
    
    .custom-slider button.slick-next{
      background-image: url('../../../../../assets/icons/right-arrow-white-icon.svg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 120px;
      width: 35px;
      right: 14px;
      font-size: 0px;
      z-index: 2;
    }
    }