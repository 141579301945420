.bottom_style {
  text-align: center;
  letter-spacing: 0px;
  color: #2b303e;
  opacity: 1;
  width: 630px;
  height: 60px;
  margin-top: 60px;
}

.mrf_contant {
  text-align: left;
  letter-spacing: 0.11px;
  color: #000000;
  opacity: 1;
}

.title_style {
  text-align: center;
  letter-spacing: 0px;
  color: #2b303e;
  opacity: 1;
}

.getTheApp4 {
  display: flex;
  margin-top: 51px;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.getTheApp2 {
  display: flex;
  align-items: center;
  margin-top: 41px;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
}

.getTheApp1 {
  display: flex;
  align-items: center;
  margin-top: 81px;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
}

.play_button {
  width: 96px;
  height: 96px;
  transform: translate(-1%, -400%);
}
.title_style {
  text-align: center;
  letter-spacing: 0px;
  color: #2b303e;
  opacity: 1;
}

.video-container {
  position: relative;
  left: 2%;
}

/* .background-image{
  width: 380px;
  height: 178px;
}
.video {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -56%); 
  width: 380px;
  height: 178px;
} 

.videos {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -56%); 
   width: 279px;
  height: 178px;
} */
/* mobile devies */
@media only screen and (max-width: 767px) {
  .app-header-container {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .app-header-img {
    width: 100%;
  }
  .plan-header-container {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .plan-header-img {
    width: 100%;
  }
  .app-heading-font {
      font-style: normal !important;
      font-variant: normal !important;
      font-size: 16px !important ;
      font-family: Trajan Pro Bold !important;
      font-weight: 400 !important;
      line-height: 43px !important;
  }
  .plan-top-container {
    padding: 20px 0px 0px;
    text-align: center;
  }
  .center-image{
    width: 100% !important;
  }
  .playstore_icons{
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  }
 .bottom-text{
  padding-left: 20px;
 }
 .bottom-container{
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
 }
 .icon-img{
  padding-bottom: 10px;
 }
 .background-image{
  width: 300px !important;
  height: 250px !important;
  box-shadow: 1px 1px 5px #2b303e;
}
.videos {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(63, 69, 81, 0.16) !important;
}
.mobile-video {
  display: block;
}
.laptop-video {
  display: none;
}
.video-container{
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16) !important;
  padding-bottom: 56.25%;
  margin-bottom: 20px;
}
.bottom-container{
  margin: auto;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
}

/* tablet devices */
@media only screen and (min-width: 768px) {
  .app-header-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .app-header-img {
    width: 100%;
  }
  .title_style {
    text-align: center;
    letter-spacing: 0px;
    color: #2b303e;
    opacity: 1;
  }
  .plan-header-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .plan-header-img {
    width: 100%;
  }
  .plan-top-container {
    padding: 25px 0px 0px 0px;
    text-align: center;
  }
  .playstore_icons {
    width: 87%;
    float: right;
    margin: 7px 0px;
  }
  .appstore-container{
    padding:7px 0px;
  }
   .background-image{
  width: 300px !important;
  height: 250px !important;
  box-shadow: 1px 1px 5px #2b303e;
}
.videos {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(63, 69, 81, 0.16) !important;
}
.mobile-video {
  display: none;
}
.laptop-video {
  display: block;
}
.video-container{
  width: 500px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16) !important;
  padding-bottom: 56.25%;
  margin-bottom: 20px;
}
.app-heading-font {
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 26px !important ;
  font-family: Trajan Pro Bold !important;
  font-weight: 400 !important;
  line-height: 43px !important;
}
.bottom-text{
  margin-top: 12px !important;
}
  
}

/* laptop devices */
@media only screen and (min-width: 1000px) {
  .app-header-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .app-header-img {
    width: 511px;
    height: 200px;
  }
  .plan-header-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .plan-header-img {
    width: 511px;
    height: 200px;
  }
  .plan-top-container {
    padding: 30px 0px 0px 0px;
    text-align: center;
  }
    .playstore_icons{
      width: 87%;
      float: right;
      margin: 7px 0px;
}
.appstore-container{
  padding:7px 0px;
}
.bottom-text{
   margin-top: 23px !important;
}
.background-image{
  width: 300px !important;
  height: 250px !important;
  box-shadow: 1px 1px 5px #2b303e;
}
.videos {
  position: absolute;
  width: 500px;
  /* height: 100%; */
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(63, 69, 81, 0.16) !important;
}
.mobile-video {
  display: block;
}
.laptop-video {
  display: none;
}
.video-container{
  width: 500px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16) !important;
  padding-bottom: 56.25%;
  margin-bottom: 20px;
}
.app-heading-font {
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 26px !important ;
  font-family: Trajan Pro Bold !important;
  font-weight: 400 !important;
  line-height: 43px !important;
}

}
/* largedevices */
@media only screen and (min-width: 1200px) {
  .app-header-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 95%;
    width: 1370px;
  }
  .app-header-img {
    max-width: 511px;
    max-height: 200px;
  }
  .plan-header-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 95%;
    width: 1370px;
  }
  .plan-header-img {
    width: 511px;
    height: 200px;
  }
  .plan-top-container {
    padding: 35px 0px 0px 0px;
    text-align: center;
  }
  .bottom-text{
    margin-top: 41px !important;
 }
  .playstore_icons{
    width: 87%;
    float: right;
    margin: 7px 0px;
}
.appstore-container{
  padding:7px 0px;
}
.background-image{
  width: 300px !important;
  height: 250px !important;
  box-shadow: 1px 1px 5px #2b303e;
}
.videos {
  position: absolute;
  width:800px;
  /* height: 100%; */
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(63, 69, 81, 0.16) !important;
}
.mobile-video {
  display: block;
}
.laptop-video {
  display: none;
}
.video-container{
  width: 800px;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(63, 69, 81, 0.16) !important;
  padding-bottom: 56.25%;
  margin-bottom: 20px;
}
.app-heading-font {
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 26px !important ;
  font-family: Trajan Pro Bold !important;
  font-weight: 400 !important;
  line-height: 43px !important;
}
}