/* mobile devices */
@media only screen and (max-width:767px) {
    .ourhistory-header-container {
        margin: auto;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

    .ourhistory-header-img {
        width: 100%;
    }

    .ourhistory-top-container {
        padding: 30px 0px 20px;
        text-align: center;
    }

    .briefhistory-content {
        padding: 10px 0px 0px 0px;
    }
    .ourhistory-topmiddle-container{
padding: 30px 0px;
    }
    .mfdplan-container{
        padding: 25px 0px 0px;
    }
    .ourhistory-rmap-svg{
        width: 2096px;
        height: 588px;
        overflow:auto ;
    }
    .ourhistory-end-container{
padding: 40px 0px 50px;
    }
    .ourhistory-endbottom-content {
        padding-bottom: 30px;
    }
    /* Our values page */
    .ourhistory-mission-title-container{
        margin:40px 0px 30px !important

    }
    .ourhistory-values-title-container{
        margin:30px 0px 30px !important

    }
    .Ourvalues-middle-top-container{
    width: 100%;
    border-radius: 10px;
    }
    .Ourvalues-middle-values-img-container{
        display: flex;
        justify-content: center;
    margin:20px 0px 0px
    }
}

/* tablet devices */
@media only screen and (min-width:768px) {

    .ourhistory-header-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

    .ourhistory-header-img {

        width: 100%;
    }
    .ourhistory-top-container {
        padding: 30px 0px 20px;
        text-align: center;
    }

    .briefhistory-content {
        padding: 10px 5px 0px 0px;
    }
    .ourhistory-topmiddle-container{
padding: 30px 0px;
    }
    .mfdplan-container{
        padding: 30px 0px 0px;
    }
    .ourhistory-rmap-svg{
        height: 784px;
    overflow: auto;
    width: 2695px;
    }
    .ourhistory-end-container{
padding: 30px 0px 40px;
    }
    .ourhistory-endbottom-content {
        padding-bottom: 30px;
    }
  /* Our values page */
  .ourhistory-mission-title-container{
    margin:40px 0px 40px !important

} .ourhistory-mission-title-container{
    margin:40px 0px 30px !important

}
  .ourhistory-values-title-container{
    margin:50px 0px 30px !important

}
  .Ourvalues-middle-top-container{
    height: 470px;
    width: 100%;
    border-radius: 10px;
    }
    .Ourvalues-middle-values-img-container{
        display: flex;
        justify-content: center;
    margin:20px 0px
    }
}

/* laptop devices */
@media only screen and (min-width:1000px) {
    .ourhistory-header-container {
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: 95%;
        padding-left: 0;
        padding-right: 0;
        width: 95% !important;
    }

    .ourhistory-header-img {
        width: 511px;
        height: 200px;
    }
    .ourhistory-top-container {
        padding: 40px 0px;
        text-align: center;
    }

    .briefhistory-content {
        padding: 10px 5px 0px 35px;
    }
    .ourhistory-topmiddle-container{
padding: 50px 0px;
    }
    .mfdplan-container{
        padding: 30px 0px 0px;
    }
    .ourhistory-end-container{
padding: 30px 0px 50px;
    }
    .ourhistory-endbottom-content {
        padding-bottom: 30px;
    }
  /* Our values page */
  .ourhistory-values-title-container{
    margin:50px 0px 30px !important

}
  .Ourvalues-middle-top-container{
    height: 430px;
    width: 100%;
    border-radius: 10px;
    }
    .Ourvalues-middle-values-img-container{
        display: flex;
        justify-content: center;
    margin:20px 0px
    }
}

/* largedevices */
@media only screen and (min-width:1200px) {
    .ourhistory-header-container {
        margin-left: auto !important;
        margin-right: auto !important;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 95%;
        width: 1370px !important;
    }

    .ourhistory-top-container {
        padding: 40px 0px;
        text-align: center;
    }

    .briefhistory-content {
        padding: 10px 5px 0px 35px;
    }
    .ourhistory-topmiddle-container{
padding: 50px 0px;
    }
    .mfdplan-container{
        padding: 30px 0px 0px;
    }
    .ourhistory-rmap-svg{
        height: 784px;
    overflow: auto;
    width: 2695px;
    }
    .ourhistory-end-container{
padding: 40px 0px 50px;
    }
    .ourhistory-endbottom-content {
        padding-bottom: 30px;
    }
      /* Our values page */
      .ourhistory-values-title-container{
        margin:50px 0px 30px !important

    }
  .Ourvalues-middle-top-container{
    height: 460px;
    width: 100%;
    border-radius: 10px;
    }
    .Ourvalues-middle-values-img-container{
        display: flex;
        justify-content: center;
    margin:20px 0px
    }
}