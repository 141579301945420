/* .arrows{
    transform: matrix(0, 1, -1, 0, 0, 0);
background: transparent url("../../../../assets/icons/right-arrow-white-icon.svg") 0% 0% no-repeat padding-box;
box-shadow: 0px 2px 10px #00000033;
opacity: 1;
} */


.drop-down-menu .MuiSelect-select {
    height: 45px !important;
    padding: 10px 15px;
    box-sizing: border-box;
  }

 /* .MuiMenu-list {
    max-height: 180px;
  } */
  .MuiMenu-list li {
    font-size: 14px;
  }
  .mobile-disabled .MuiMenu-list li {
    font-size: 0px !important;
    height: 0px !important;
  }
  .MuiSelect-select.MuiSelect-outlined{
    border: 1px solid transparent;
    padding-left: 0;
   }

.carousal-bottom {
    background: var(--unnamed-color-40aeba) 0% 0% no-repeat padding-box;
    background: #40AEBA 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #00000029;
    opacity: 1;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;

}




.carousal-middle-content {

    color: #ffffff;
    padding: 13px 0px 13px 0px;
    text-align: center;
    opacity: 1;

}

.business {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 120px;
    width: 350px;
    background: #FFFFFF;
    border: 1px solid #40AEBA;
    border-radius: 20px;
    opacity: 1;
    align-items: center;
    cursor: pointer;
    padding: 0px 20px;
}



.individual-container {
    padding: 50px 0px 50px 0px;
    background: #FAFAFB 0% 0% no-repeat padding-box;

    opacity: 1;
}


.individual-container-root {
    padding-right: 15px;
    padding-left: 15px;
}

.carousal-middle-container{
    width: 100%   ;
    margin-top: -20px;
      background: var(--unnamed-color-000000) 0 0 no-repeat padding-box;
      background: #000 0 0 no-repeat padding-box;
     
      /* bottom: 21%; */
      display: flex;
      flex-direction: column!important;
      padding-left: 15px;
      padding-right: 15px;
      /* padding-bottom: 3px;
      padding-left: 40px;
      padding-right: 40px;
      position: absolute;
      width: 7.5%; */
      
  }
/* mobile devices */
@media only screen and (max-width:767px) {
    .offer-button{
     z-index: 100;
    position: fixed !important;
    top: 278px !important;
    right: -297px;
    }
    .offerlimited-btn{
        width: 16%;
    }

    .slider-icon button[aria-label="Next"],
.slider-icon button[aria-label="Previous"] {
    background: transparent !important;
   margin: auto;
    top: 0% !important;
}
.arrows-carousal{
 
    width: 30%;
}
    .carousal-img-bigsize {
        display: none !important
    }
    .carousal-img-smallsize {
        height: 100%;
       width: 100%;

    }
    .contactus-button{
        position: absolute;
        top: 14%;
        right: -15px;
    }
    /* .carousal-middle-container{
        display: flex;
        flex-direction: column !important;
        position: absolute;
      
        bottom: 7%;
        width: 7%;
      
        background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
        background: #000000 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 0.8;
       
     
    } */
    .carousal-middle-content {

        color: #ffffff;
        padding: 7px 0px 7px 0px;
        text-align: center;
        opacity: 1;
    
    }
    .carousal-middle-content-1 {
        color: #ffffff;
        padding-bottom: 7px;
        text-align: center;
    
    }
    .getting-started {
        width: 132px;
        height: 25px;
        border-radius: 10px !important;
        background: #FF3535 !important;
        color: #ffffff !important;
        opacity: 1;
        margin: 0px auto 7px !important;
        border-color: #ff3535 !important;
        text-transform: unset !important;
    }
    .Homepage-popup{
        position: absolute;
        top: 53%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 79% !important;
      /* height: 100%; */
        background-color: #fff;
        border: none;
        border-radius: 5px;
        padding: 16px;
    }
    .Home-page-pop-close{
        margin-top: 15px;
        height: 20px;
        width: 20px;
    } 
    .laptop-home-popup-content{
display: none;
    }
.mobile-home-popup-content{
display: block;
}
    .home-goverment-form-alert-popup{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width:90%;
       
        background-color: #fff;
        border: none;
        border-radius: 5px;
        padding: 8px;
     
    }
    .home-govermrnt-form-popup{
        position: absolute;
        top: 53%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90% !important;
        overflow-y:scroll;
        max-height:84% ;
        background-color: #fff;
        border: none;
        border-radius: 5px;
        padding: 16px;
    }
    .individual-container {
        padding: 50px 0px 30px 0px;
        background: #FAFAFB 0% 0% no-repeat padding-box;
    justify-content: center;
        opacity: 1;
    }
    .invidual-item-container{
        display: flex;
        flex-direction: column;
        justify-content:center !important;
    
    }
    
    .indvidualcontainer-topbar{
      width: 66%;
      margin-bottom:25px !important;

    }
    .indvidualcontainer {
        display: flex;
        flex-direction: row;
        justify-content:center;
        height: 70px !important;
        width: 100%;
        /* margin-left: 30px; */
        margin-bottom: 20px;
        background: #FFFFFF;
        border: 1px solid #40AEBA;
        border-radius: 13px;
        opacity: 1;
        align-items: center;
        cursor: pointer;
        margin: 0px;
        padding: 0px  ;
    }

    .individual-img {
        margin-left: -5px;
        margin-right: 5%;
        height: 51px;
        width: 60px;
    }
    .goverment-img{
        margin-left: 7px;
        margin-right: 5%;
        height: 51px;
        width: 60px;
        
    }

    .MuiMenu-list {
        max-height: 180px;
        max-width:  16em;
      }

}

/* 
tablet devices */
@media only screen and (min-width:768px) and (max-width:999px) {
    .offer-button{
        z-index: 100;
       position: fixed !important;
       top: 391px !important;
       right: -296px;
       }
       .offerlimited-btn{
        width: 16%;
    }
    .slider-icon button[aria-label="Next"],
.slider-icon button[aria-label="Previous"] {
    background: transparent !important;
   margin: auto;
    top: -100% !important;
}
.arrows-carousal{
 
    width: 55%;
}
    .carousal-img-bigsize {
        display: none !important
    }
    .carousal-img-smallsize {
        height: 100%;
       width: 100%;

    }
    .contactus-button{
        position: absolute;
        top: 20%;
        right: -15px;
    }
    /* .carousal-middle-container{
        display: flex;
        flex-direction: column !important;
        position: absolute;
        padding: 0px auto;
        bottom: 25%;
        width: 7%;
        background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
        background: #000000 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 0.8;
       
     
    } */
    .carousal-middle-content-1 {
        color: #ffffff;
        padding-bottom: 10px;
        text-align: center;
    
    }
    .getting-started {
        width: 132px;
        height: 25px;
        border-radius: 10px !important;
        background: #FF3535 !important;
        color: #ffffff !important;
        opacity: 1;
        margin: 0px auto 18px !important;
        border-color: #ff3535 !important;
        text-transform: unset !important;
    }
    .Homepage-popup{
        position: absolute;
        top: 53%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 79% !important;
      /* height: 100%; */
        background-color: #fff;
        border: none;
        border-radius: 5px;
        padding: 16px;
    }
    .Home-page-pop-close{
        margin-top: 15px;
        height: 20px;
        width: 20px;
    } 
    .laptop-home-popup-content{
display: none;
    }
.mobile-home-popup-content{
display: block;
}
    .home-goverment-form-alert-popup{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width:90%;
       
        background-color: #fff;
        border: none;
        border-radius: 5px;
        padding: 8px;
     
    }
    .home-govermrnt-form-popup{
        position: absolute;
        top: 53%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90% !important;
       max-height: 84%;
        background-color: #fff;
        border: none;
        border-radius: 5px;
        padding: 16px;
    }
    .invidual-item-container{
        display: flex;
        flex-direction: column;
        justify-content:center !important;
  
    }
    
    .individual-container {
        padding: 50px 0px 50px 0px;
        background: #FAFAFB 0% 0% no-repeat padding-box;
    justify-content: center;
        opacity: 1;
    }
    .indvidualcontainer-topbar{
      width: 60%;
      margin-bottom:15px !important;

    }

    .indvidualcontainer {
        display: flex;
        flex-direction: row;
        justify-content:space-evenly;
        height: 70px !important;
        width: 100%;
        /* margin-left: 30px; */
        margin-bottom: 20px;
        background: #FFFFFF;
        border: 1px solid #40AEBA;
        border-radius: 13px;
        opacity: 1;
        align-items: center;
        cursor: pointer;
        margin: 0px;
        padding: 0px  ;
    }

    .individual-img {
        margin-left: -5px;
        height: 51px;
        width: 60px;
    }
    .goverment-img{
        margin-left: -5px;
        height: 51px;
        width: 60px;
    }



}
/*laptop devices */
@media only screen and (min-width:1000px) and (max-width:1199px) {
    .offer-button{
        z-index: 100;
       position: fixed !important;
       top: 430px !important;
       right: -297px;
       }
       .offerlimited-btn{
        width: 16%;
    }
    .slider-icon button[aria-label="Next"],
.slider-icon button[aria-label="Previous"] {
    background: transparent !important;
    width: 170px;
    height: 170px;
    top: -89% !important;
}
.arrows-carousal{
    width: 60%;
}
    .carousal-img-bigsize {
        
        width: 100%;
        }
        .carousal-img-smallsize {
            display: none !important
           
        }
        .contactus-button{
            position: absolute;
            top: 17%;
            right: -10px;
        }
        /* .carousal-middle-container{
            background: var(--unnamed-color-000000) 0 0 no-repeat padding-box;
            background: #000 0 0 no-repeat padding-box;
            border-radius: 15px;
            bottom: 12%;
            display: flex;
            flex-direction: column!important;
            opacity: .8;
            padding-bottom: 10px;
            padding-left: 30px;
            padding-right: 30px;
            position: absolute;
            width: 7.5%;
         
        } */
        .carousal-middle-content-1 {
            color: #ffffff;
            padding-bottom: 15px;
            text-align: center;
        
        }
        .getting-started {
            width: 220px;
            height: 60px;
            border-radius: 30px !important;
            background: #FF3535 !important;
            color: #ffffff !important;
            opacity: 1;
            margin: 20px auto !important;
            border-color: #ff3535 !important;
            text-transform: unset !important;
        } 
.Homepage-popup{
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    max-height: 600px;
    background-color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    padding: 16px;
}

.Home-page-pop-close{
    margin-top: 13px;
    height: 20px;
    width: 20px;
}
.laptop-home-popup-content{
    display: block;
        }
    .mobile-home-popup-content{
    display: none;
    }
.home-goverment-form-alert-popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
   
    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px;
 
}

.home-govermrnt-form-popup{
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 605px;
    max-height: 600px;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 16px;
}
.individual-container-root {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
}
.invidual-item-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
}
    .indvidualcontainer {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: 120px !important;
        width: 300px;
        margin-bottom: 20px;
        background: #FFFFFF;
        border: 1px solid #40AEBA;
        border-radius: 20px;
        opacity: 1;
        align-items: center;
        cursor: pointer;
        padding: 0px 20px;
    }

    .individual-img {
        height: 60px;
        width: 60px;
        margin:0px 30px 0px  0px
    }
    .goverment-img{
        height: 60px;
        width: 60px;
        margin:0px 30px 0px  0px
    }
    

}

/* large devices */
@media only screen and (min-width:1200px)  {
    .offer-button{
        z-index: 100;
       position: fixed !important;
       top:420px !important;
       right: -297px;
       }
       .offerlimited-btn{
        width: 16%;
    }

    .slider-icon button[aria-label="Next"],
.slider-icon button[aria-label="Previous"] {
    background: transparent !important;
    width: 170px;
    height: 170px;
    top: -89% !important;
}
.arrows-carousal{
    width: 60%;
}
    .carousal-img-bigsize {
        
        width: 100%;
        }
        .carousal-img-smallsize {
            display: none !important
           
        }
        .contactus-button{
            position: absolute;
            top: 20%;
            right: -10px;
        }
        .carousal-middle-container{
           
          width: 100%   ;
          margin-top: -20px;
            background: var(--unnamed-color-000000) 0 0 no-repeat padding-box;
            background: #000 0 0 no-repeat padding-box;           
            /* bottom: 21%; */
            display: flex;
            flex-direction: column!important;
            
            /* padding-bottom: 3px;
            padding-left: 40px;
            padding-right: 40px;
            position: absolute;
            width: 7.5%; */
            
        }
        .carousal-middle-content-1 {
            color: #ffffff;
            padding-bottom: 11px;
            padding-top: 11px;
            text-align: center;
        
        }
        .getting-started {
            width: 220px;
            height: 60px;
            border-radius: 30px !important;
            background: #FF3535 !important;
            color: #ffffff !important;
            opacity: 1;
            margin: 20px auto !important;
            border-color: #ff3535 !important;
            text-transform: unset !important;
        } 
.Homepage-popup{
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    max-height: 600px;
    background-color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    padding: 16px;
}

.Home-page-pop-close{
    margin-top: 13px;
    height: 20px;
    width: 20px;
}
.laptop-home-popup-content{
    display: block;
        }
    .mobile-home-popup-content{
    display: none;
    }
.home-goverment-form-alert-popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
   
    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px;
 
}

.home-govermrnt-form-popup{
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 605px;
    max-height: 600px;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 16px;
}
.individual-container-root {
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    width: 1370px;
}

.invidual-item-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
}
    .indvidualcontainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 120px !important;
        width: 350px;
        margin:0px 0px 20px 0px;
        background: #FFFFFF;
        border: 1px solid #40AEBA;
        border-radius: 20px;
        opacity: 1;
        align-items: center;
        cursor: pointer;
        padding: 0px 20px;
    }

    .individual-img {
        height: 60px;
        width: 60px;
        margin:0px 30px 0px  0px
    }
    .goverment-img{
        height: 60px;
        width: 60px;
        margin:0px 30px 0px  0px
    }

}